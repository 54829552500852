<template>
  <c-box h="150px">
    <c-box
      v-if="!image"
      w="100%"
      h="100%"
    >
      <vue-skeleton-loader
        type="rect"
        width="100%"
        height="100%"
        rounded
        animation="fade"
      />
    </c-box>
    <c-image
      v-else
      :src="image ? image : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039'"
      alt="dietela"
      w="100%"
      object-fit="cover"
      h="100%"
    />
  </c-box>
</template>

<script>
import axios from 'axios'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'CardArtikel',
  components: { VueSkeletonLoader },
  props: {
    data: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      image: '',
    }
  },  
  watch: {
    data: {
      handler() {
        this.getImage()
      },
    },
  },
  async mounted() {
    await this.getImage()  
  },
  methods: {
    async getImage() {
      await axios.get(this.data)
        .then(res => {
          if (res.data && res.data.length > 0) {
            this.image = res.data[0].source_url
          }
        })
    },
  }, 
}
</script>
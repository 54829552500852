var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-box', {
    attrs: {
      "w": ['100%', '80%'],
      "max-width": "1100px",
      "justify-content": "center",
      "align-items": "center",
      "pb": "1rem",
      "mx": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "w": "100%",
      "justify-content": "center",
      "align-content": "center",
      "px": ['16px', '0px'],
      "pos": "relative"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '28px'],
      "font-weight": ['500', '700'],
      "color": "#000000",
      "mb": ['6px', '6px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" Artikel Terbaru ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "mb": ['6px', '6px'],
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": ['6px', '10px'],
      "flex-direction": "row"
    }
  }, _vm._l(_vm.categories, function (menu, index) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: 'transparent'
          },
          '&.active, &:focus, &:active': {
            backgroundColor: '#C7F9E3',
            border: '2px solid #008C81',
            borderRadius: '30px',
            '@media(max-width: 767px)': {
              border: '1px solid #008C81'
            }
          }
        },
        expression: "{\n              ':hover': {\n                backgroundColor: 'transparent',\n              },\n              '&.active, &:focus, &:active': {\n                backgroundColor: '#C7F9E3',\n                border: '2px solid #008C81',\n                borderRadius: '30px',\n                '@media(max-width: 767px)': {\n                  border: '1px solid #008C81',\n                },\n              },\n            }"
      }],
      key: index,
      class: {
        active: menu.id == _vm.active
      },
      attrs: {
        "font-weight": "400",
        "font-family": "Roboto",
        "font-size": ['12px', '16px'],
        "color": "#008C81",
        "h": ['fit-content', 'auto'],
        "py": ['6px', '10px'],
        "px": ['6px', '16px'],
        "align": "center",
        "background-color": "transparent"
      },
      on: {
        "click": function click($event) {
          return _vm.selectMenu(menu);
        }
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")]);
  }), 1), _c('c-link', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: 'transparent'
        },
        ':focus': {
          outline: 'none',
          boxShadow: 'none !important',
          background: 'none !important'
        }
      },
      expression: "{\n            ':hover': {\n              backgroundColor: 'transparent',\n            },\n            ':focus': {\n              outline: 'none',\n              boxShadow: 'none !important',\n              background: 'none !important',\n            },\n          }"
    }],
    attrs: {
      "color": "#008C81",
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "font-family": "Roboto",
      "as": "a",
      "href": "".concat(_vm.ENV.URL_DIETELA_WORDPRESS, "/blog/")
    }
  }, [_vm._v(" Lihat Semua ")])], 1)], 1), _c('c-box', [_vm.post.length > 0 ? _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '10px 10px',
          '@media(max-width: 767px)': {
            padding: '0 0 0 10px'
          }
        }
      },
      expression: "{\n          '.slick-slide': {\n            padding: '10px 10px',\n            '@media(max-width: 767px)': {\n              padding: '0 0 0 10px',\n            }\n          }\n        }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.post, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "bg-color": "white",
        "border-radius": "12px",
        "overflow": "hidden",
        "as": "a",
        "p": ['5px', '10px'],
        "box-shadow": ['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)'],
        "href": item.link || '',
        "max-w": ['300px', '100%']
      }
    }, [_c('c-box', {
      attrs: {
        "h": ['54px', '62px'],
        "bg-color": "#008C81",
        "border-radius": ['6px 6px 20px 20px', '8px 8px 30px 30px'],
        "mb": ['5px', '10px'],
        "p": ['4px 0px', '4px 10px']
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['16px', '18px'],
        "font-weight": ['400', '500'],
        "px": "6px",
        "font-family": "Roboto",
        "align": "center",
        "color": "white",
        "overflow": "hidden"
      }
    }, [_vm._v(" " + _vm._s(_vm.splitServiceTarget(item.title.rendered)) + " ")])], 1), _c('CardArtikel', {
      attrs: {
        "data": "".concat(_vm.ENV.URL_DIETELA_WORDPRESS, "/wp-json/wp/v2/media?parent=").concat(item.id)
      }
    })], 1);
  }), 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <c-box
    v-if="getIsShowProduct"
    position="relative"
    :w="['100%', '73%']"
    mx="auto"
    z-index="11"
  >
    <c-text
      display="block"
      font-family="Roboto"
      :font-weight="['600', '700']"
      :font-size="['14px', '28px']"
      :mt="['1.5rem', '1rem']"
      :mb="['8px', '1rem']"
      text-align="center"
    >
      Pilihan Program {{ product?.name }} sesuai kebutuhanmu
    </c-text>

    <c-box
      v-if="productServices.length > 0"
      width="100%"
      max-width="1100px"
      :px="['10px', '0']"
      pb="1rem"
      mx="auto"
      class="tableGsap"
    >
      <c-box
        pos="relative"
        :display="['none', 'table']"
        as="table"
        width="100%"
        class="table-recommendation"
        z-index="3"
      >
        <c-box
          as="thead"
          color="#FFF"
          background-color="primary.400"
          font-weight="700"
          border-radius="8px"
        >
          <c-box
            v-chakra="{
              '>:first-child': {
                borderLeft: '1px solid #008C81',
              },
              '>:last-child': {
                borderRight: '1px solid #008C81',
              },
              '>:not(:last-child):not(:first-child)': {
                borderRight: '1px solid #005A64',
                borderLeft: '1px solid #005A64',
              },
            }"
            as="tr"
          >
            <c-box
              as="th"
              text-align="center"
              font-size="24px"
              py="1rem"
            >
              Fasilitas Layanan
            </c-box>
            <c-box
              v-for="item in productServices"
              :key="item.id"
              as="th"
              flex-direction="column"
              justify="center"
              py="1rem"
              width="250px"
            >
              <c-text
                font-size="20px"
                margin-bottom="8px"
                font-weight="700"
              >
                {{ item?.programsService }} {{ item?.duration }} Hari
              </c-text>
              <c-text
                font-size="32px"
              >
                {{ formatCurrency(item?.price, 2) }}
              </c-text>
              <c-text
                font-size="16px"
                font-weight="400"
              >
                (Rp {{ pricePerDay(item?.price) }} ribuan/hari)
              </c-text>
            </c-box>
          </c-box>
        </c-box>
        <c-box
          v-chakra="{
            '> tr': {
              borderLeft: '1px solid #008C81',
              borderRight: '1px solid #008C81',
            },
          }"
          as="tbody"
          background-color="#FFF"
          color="#000"
          :font-size="['12px', '16px']"
        >
          <c-box
            v-chakra="{
              '>:not(:last-child):not(:first-child)': {
                borderRight: '1px solid #C4C4C4',
                borderLeft: '1px solid #C4C4C4',
              },
            }"
            as="tr"
          >
            <c-box as="td" />
            <c-box
              v-for="(item, idx) in productServices"
              :key="`sub-${idx}`"
              as="td"
              w="fit-content"
              p="20px 10px 10px 10px"
            >
              <c-text
                background-color="secondary.50"
                color="primary.400"
                font-size="14px"
                font-weight="400"
                text-align="center"
                padding="6px"
              >
                {{ item?.description }}
              </c-text>
            </c-box>
          </c-box>
          <c-box
            v-for="(facility, i) in facilities"
            :key="`tr-${i}`"
            v-chakra="{
              '>:not(:last-child):not(:first-child)': {
                borderRight: '1px solid #C4C4C4',
                borderLeft: '1px solid #C4C4C4',
              },
            }"
            as="tr"
          >
            <c-box
              v-for="k in (getTotalProductService + 1)"
              :key="`td-${i}-${k}`"
              as="td"
              px="1rem"
              :text-align="k === 1 ? 'left' : 'center'"
            >
              <c-box
                v-if="k === 1"
                w="100%"
                py="1rem"
                :px="k === 1 ? '12px' : '0'"
                border-bottom="1px solid #C4C4C4"
              >
                {{ facility }}
              </c-box>
              <c-box
                v-else
                w="100%"
                py="1rem"
                border-bottom="1px solid #C4C4C4"
                display="flex"
                justify-content="center"
              >
                <inline-svg
                  v-if="isCheckList(getFacilityValue(facility, productServices[k-2]?.productFacilitiesComparation))"
                  :src="checkIcon(getFacilityValue(facility, productServices[k-2]?.productFacilitiesComparation)) ? iconCircleCheck : iconCircleClose"
                  class="icon-list"
                  :fill="checkIcon(getFacilityValue(facility, productServices[k-2]?.productFacilitiesComparation)) ? '#008C81' : '#D32737'"
                />
                <c-box
                  v-else
                >
                  {{ getFacilityValue(facility, productServices[k-2]?.productFacilitiesComparation) }}
                </c-box>
              </c-box>
            </c-box>
          </c-box>
          <c-box
            v-chakra="{
              '>:not(:last-child):not(:first-child)': {
                borderRight: '1px solid #C4C4C4',
                borderLeft: '1px solid #C4C4C4',
              },
            }"
            as="tr"
            border-bottom="1px solid #008C81"
          >
            <c-box 
              as="td"
              text-align="center"
            >
              <c-button
                max-width="270px"
                width="100%"
                variant="outline"
                variant-color="primary"
                border-radius="60px"
                height="50px"
                @click.prevent="onClickMoreInfo(product?.slug)"
              >
                Info Lebih Lengkap
              </c-button>
            </c-box>
            <c-box
              v-for="item in productServices"
              :key="`buy-${item?.id}`"
              as="td"
              px="1rem"
              py="1rem"
            >
              <c-button
                v-chakra="{
                  ':hover': {
                    color:'primary.500',
                    background:'secondary.500',
                  },
                  ':active': {
                    color: 'white',
                    background: 'secondary.600',
                  },
                }"
                width="100%"
                variant="solid"
                color="primary.400"
                background="secondary.400"
                border-radius="60px"
                height="50px"
                @click.prevent="handleSelectProgram({
                  productId: item?.productId,
                  productServiceId: item?.id,
                  duration: productDuration
                })"
              >
                Beli Program
              </c-button>
            </c-box>
          </c-box>
        </c-box>
      </c-box>

      <c-box
        pos="relative"
        :display="['block', 'none']"
        z-index="3"
        width="100%"
      >
        <c-grid
          v-chakra="{
            '> :not(:first-child) > div': {
              borderLeft:'1px solid #005A64',
            },
          }"
          w="100%"
          :template-columns="`repeat(${getTotalProductService}, 1fr)`"
          background-color="primary.400"
          color="#FFF"
          font-size="14px"
          font-weight="500"
          border-top-left-radius="8px"
          border-top-right-radius="8px"
          border="1px solid #008C81"
        >
          <c-box
            v-for="(item) in productServices"
            :key="item.programsService"
            w="100%"
            py="6px"
          >
            <c-flex
              w="100%"
              flex-direction="column"
              align-items="center"
            >
              <c-text mb="8px">
                {{ item?.programsService }} {{ item?.duration }} Hari
              </c-text>
              <c-text
                font-size="16px"
              >
                {{ formatCurrency(item?.price, 2) }}
              </c-text>
              <c-text 
                font-size="10px"
                font-weight="400"
              >
                (Rp {{ pricePerDay(item?.price) }} ribuan/hari)
              </c-text>
            </c-flex>
          </c-box>
        </c-grid>
        <c-grid
          v-chakra="{
            '> :not(:first-child)': {
              borderLeft:'1px solid #9CD8FC',
            },
          }"
          w="100%"
          :template-columns="`repeat(${getTotalProductService}, 1fr)`"
          background-color="secondary.50"
          border-left="1px solid #008C81"
          border-right="1px solid #008C81"
        >
          <c-box
            v-for="(item, idx) in productServices"
            :key="`sub-${idx}`"
            w="100%"
            p="6px"
            color="primary.400"
            font-size="12px"
            font-weight="400"
            text-align="center"
          >
            {{ item?.description }}
          </c-box>
        </c-grid>
        <c-box
          p="8px"
          color="#000"
          font-size="12px"
          font-weight="400"
          background-color="#FFF"
          border-bottom-left-radius="8px"
          border-bottom-right-radius="8px"
          border-left="1px solid #008C81"
          border-right="1px solid #008C81"
          border-bottom="1px solid #008C81"
        >
          <c-box
            v-for="(facility, index) in facilities"
            :key="`facility-${index}`"
            mb="8px"
          >
            <c-box
              p="6px 12px"
              background-color="primary.50"
              text-align="center"
            >
              {{ facility }}
            </c-box>
            <c-grid
              v-chakra="{
                '> :not(:first-child)': {
                  borderLeft:'1px solid #C4C4C4',
                },
              }"
              w="100%"
              :template-columns="`repeat(${getTotalProductService}, 1fr)`"
            >
              <c-flex
                v-for="j in getTotalProductService"
                :key="j"
                w="100%"
                py="4px"
                flex-direction="column"
                align-items="center"
              >
                <inline-svg
                  v-if="isCheckList(getFacilityValue(facility, productServices[j-1]?.productFacilitiesComparation))"
                  :src="checkIcon(getFacilityValue(facility, productServices[j-1]?.productFacilitiesComparation)) ? iconCircleCheck : iconCircleClose"
                  class="icon-list"
                  :fill="checkIcon(getFacilityValue(facility, productServices[j-1]?.productFacilitiesComparation)) ? '#008C81' : '#D32737'"
                />
                <c-box
                  v-else
                >
                  {{ getFacilityValue(facility, productServices[j-1]?.productFacilitiesComparation) }}
                </c-box>
              </c-flex>
            </c-grid>
          </c-box>

          <c-grid
            v-chakra="{
              '> :not(:first-child)': {
                borderLeft:'1px solid #F2F2F2',
              },
            }"
            w="100%"
            :template-columns="`repeat(${getTotalProductService}, 1fr)`"
          >
            <c-box
              v-for="item in productServices"
              :key="item.id"
              p="6px"
            >
              <c-button
                v-chakra="{
                  ':hover': {
                    color:'primary.500',
                    background:'secondary.500',
                  },
                  ':active': {
                    color: 'white',
                    background: 'secondary.600',
                  },
                }"
                width="100%"
                variant="solid"
                color="primary.400"
                background="secondary.400"
                border-radius="16px"
                height="34px"
                font-size="12px"
                px="0"
                @click.prevent="handleSelectProgram({
                  productId: item?.productId,
                  productServiceId: item?.id,
                  duration: productDuration
                })"
              >
                Beli Program
              </c-button>
            </c-box>
          </c-grid>
          <c-box p="6px">
            <c-button
              width="100%"
              variant="outline"
              variant-color="primary"
              border-radius="50px"
              height="34px"
              font-size="12px"
              @click.prevent="onClickMoreInfo(product?.slug)"
            >
              Info Lebih Lengkap
            </c-button>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import iconCircleClose from '@/assets/icons/icon-circle-close.svg'
import iconCircleCheck from '@/assets/icons/icon-circle-check.svg'
import { formatCurrency } from '@/utils/format-currency'
import { reqProgramPage } from '@/requests/dietela-api/general/program-page'

export default {
  name: 'ProgramRecommendation',
  props: {
    productSlug: {
      type: [String, null],
      default: null,
    },
    categoryId: {
      type: [String, null],
      default: null,
    },
    productDuration: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      productServices: [],
      facilities: [],
      iconCircleClose,
      iconCircleCheck,
    }
  },
  computed: {
    getIsShowProduct() {
      if (this.product?.slug?.toLowerCase() === 'for-enterprise') {
        return false
      }
      return !!this.product
    },
    product() {
      if (this.productServices.length > 0) {
        return this.productServices[0]?.product
      }
      return null
    },
    getTotalProductService() {
      return this.productServices.length
    },
  },
  watch: {
    productSlug: {
      immediate: true,
      handler(val) {
        if (val == null) return
        reqProgramPage.target(this.$store.getters.axios, {
          slug: val,
          params: {
            duration: this.productDuration,
            group: 'comparation',
          },
        })  
          .then((res) => {
            this.productServices = res.data?.data
            const firstDataFacilities = res.data?.data[0]?.productFacilitiesComparation.map(
              (item) => item?.productFacilities?.facility,
            ) || []
            this.facilities = firstDataFacilities
          })
      },
    },
  },
  methods: { 
    formatCurrency,
    pricePerDay(price) {
      return Math.floor((price / 30) / 1000)
    },
    onClickMoreInfo(slug) {
      if (slug.toLowerCase().includes('enterprise')) {
        this.$router.push({
          name: 'client.enterpriseService',
        })
        return
      }
      this.$router.push({
        name: 'client.program.detail',
        params: {
          id: slug,
        },
      })
    },
    handleSelectProgram({ productId, productServiceId, duration }) {
      this.$router.push({
        name: 'client.buy.step-1',
        query: {
          category_id: this.categoryId,
          product_id: productId,
          product_service_id: productServiceId,
          duration: duration,
        },
      })
    },
    getFacilityValue(label, data) {
      return data?.find(it => it?.productFacilities?.facility === label)?.value
    },
    isCheckList(value) {
      if (value && value?.includes('[x]') || value?.includes('[v]')) {
        return 1
      }
      return 0
    },
    checkIcon(value) {
      if (value && value?.includes('[x]')) {
        return 0
      }
      return 1
    },
  },
}
</script>

<style scoped>
.table-recommendation {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  overflow: hidden;
  border-radius: 8px;
}
.icon-list {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .icon-list {
    width: 20px;
    height: 20px;
  }
}
</style>
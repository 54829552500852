var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": ['100%', '73%'],
      "mx": "auto",
      "gap": "6px"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": "row",
      "justify-content": "center",
      "align-items": "center",
      "background-color": "#FFFFFF",
      "box-shadow": ['0px 2px 4px rgba(0, 0, 0, 0.1)', '0px 4px 12px rgba(0, 0, 0, 0.2)'],
      "border-radius": "12px",
      "p": ['16px 10px 10px 10px', '20px 30px'],
      "gap": "10px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['100px', '250px'],
      "h": ['128px', '215px'],
      "margin-left": ['0px', '24px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-direction": "column",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['100px', '120px'],
      "h": ['20px', '42px'],
      "mb": ['16px', '24px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['180px', '400px'],
      "h": ['12px', '18px'],
      "mb": ['5px', '6px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['180px', '400px'],
      "h": ['12px', '18px'],
      "mb": ['5px', '6px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['140px', '350px'],
      "h": ['12px', '18px'],
      "mb": ['16px', '24px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['120px', '190px'],
      "h": ['30px', '50px'],
      "border-radius": "25px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": "",
      "radius": "30"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
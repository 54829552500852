var render = function () {
  var _vm$data, _vm$data2, _vm$data3, _vm$data4, _vm$data5, _vm$data5$imageDescri, _vm$data6, _vm$data6$imageDescri, _vm$data7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !!_vm.data ? _c('c-box', {
    attrs: {
      "position": "relative",
      "height": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "max-w": "1100px",
      "mx": "auto",
      "w": ['100%', '80%'],
      "pb": "16px",
      "position": "relative"
    }
  }, [_c('c-box', {
    attrs: {
      "mx": ['16px', '0']
    }
  }, [_c('c-grid', {
    attrs: {
      "template-columns": ['1fr', '1fr 2fr'],
      "mx": "auto",
      "gap": "16px",
      "px": ['20px', '30px'],
      "pt": ['30px'],
      "pb": ['10px']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": ['space-between', 'center'],
      "gap": "16px",
      "w": "100%",
      "position": "relative"
    }
  }, [_c('BaseText', {
    attrs: {
      "display": ['none', 'block'],
      "size-mobile": "20px",
      "size-desktop": "28px",
      "text-align": "center",
      "color": "primary.400"
    },
    domProps: {
      "innerHTML": _vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.header)
    }
  }), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align": ['flex-end', 'center'],
      "mt": ['-30px', '0'],
      "position": "relative"
    }
  }, [_c('BaseText', {
    attrs: {
      "display": ['block', 'none'],
      "size-mobile": "20px",
      "size-desktop": "28px",
      "text-align": "center",
      "color": "primary.400",
      "width": "220px",
      "top": "19px",
      "right": "100px",
      "position": "absolute"
    },
    domProps: {
      "innerHTML": _vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.header)
    }
  }), !!((_vm$data3 = _vm.data) !== null && _vm$data3 !== void 0 && _vm$data3.image) ? _c('c-image', {
    attrs: {
      "src": (_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.image,
      "max-w": ['126px', '230px'],
      "align": "center !important",
      "margin-right": ['20px', '0']
    }
  }) : _vm._e(), _c('c-box', {
    attrs: {
      "p": ['8px 10px'],
      "gap": ['10px'],
      "border-radius": "8px",
      "align": "center",
      "bg": "#008C81",
      "mt": ['-30px', '-50px']
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "20px",
      "text-align": "center",
      "color": "white",
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : (_vm$data5$imageDescri = _vm$data5.imageDescription) === null || _vm$data5$imageDescri === void 0 ? void 0 : _vm$data5$imageDescri.name) || '-') + " ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px-3",
      "text-align": "center",
      "color": "white",
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$imageDescri = _vm$data6.imageDescription) === null || _vm$data6$imageDescri === void 0 ? void 0 : _vm$data6$imageDescri.title) || '-') + " ")])], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": ['16px', '20px']
    }
  }, _vm._l((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : _vm$data7.descriptionItems, function (item, index) {
    return _c('c-flex', {
      key: index,
      attrs: {
        "flex-direction": "rows",
        "gap": ['13px', '21px'],
        "border-bottom": ['0.5px solid #C4C4C4', '0'],
        "background-color": "white",
        "padding": "10px",
        "align-items": "center"
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/verified.svg'),
        "w": ['25px', '30px'],
        "h": ['25px', '30px']
      }
    }), _c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "gap": ['0px', '12px']
      }
    }, [_c('c-text', {
      attrs: {
        "font-family": "Roboto",
        "font-weight": ['600', '500'],
        "font-size": ['12px', '18px'],
        "color": "#111111"
      }
    }, [_vm._v(" " + _vm._s(item.item) + " ")]), _c('c-text', {
      attrs: {
        "font-family": "Roboto",
        "font-weight": ['400'],
        "font-size": ['12px', '16px'],
        "color": "#111111"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
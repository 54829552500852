<template>
  <c-box
    pos="relative"
    w="100%"
    max-width="1100px"
    mx="auto"
    pb="16px"
  >
    <c-flex
      justify-content="center"
      flex-direction="column"
      align-items="center"
      :py="['16px', '20px']"
      :px="['16px', '20px']"
      :mx="['16px', '0px']"
      :background-color="['#FFFFFF80', '#FFFFFF']"
    >
      <c-text
        font-family="Roboto"
        :font-size="['16px', '28px']"
        :color="['#111111', '#008C81']"
        :mb="['24px', '64px']"
        :font-weight="['500', '700']"
        :text-align="['left', 'center']"
        w="100%"
      >
        Tahapan Memulai Layanan
      </c-text>
      <c-flex
        :flex-direction="['column', 'row']"
        w="100%"
        justify-content="space-evenly"
        :px="['0px', '0px']"
        :mb="['0', '24px']"
      >
        <c-box
          v-for="(item, index) in stages"
          :key="index"
          :mb="['32px', '0']"
        >
          <c-flex
            :w="['100%' , '200px']"
            :h="['82px' , '200px']"
            background-color="#00A68C"
            justify-content="center"
            :align-items="['flex-end', 'center']"
            :flex-direction="isImageOnRight(index) ? 'row-reverse' : 'row'"
            pos="relative"
            :border-top-right-radius="[isImageOnRight(index) ? '0' : '30px', '100%']"
            :border-bottom-right-radius="[isImageOnRight(index) ? '0' : '30px', '100%']"
            :border-top-left-radius="[isImageOnRight(index) ? '30px' : '0', '100%']"
            :border-bottom-left-radius="[isImageOnRight(index) ? '30px' : '0', '100%']"
          >
            <c-image 
              :pos="['relative', 'absolute']"
              :w="['100px', '150px']"
              h="auto"
              object-fit="contain"
              object-position="right bottom"
              :right="['unset', item.right]"
              :top="['unset', item.top]"
              :padding-bottom="['12px', '0']"
              :src="item.image"
            />
            <c-flex
              flex-grow="1"
              :flex-direction="[isImageOnRight(index) ? 'row' : 'row-reverse', 'column']"
              align-items="center"
              :gap="['1rem', '0']"
              :h="['100%', 'auto']"
              :pl="[isImageOnRight(index) ? '20px' : '0', '0']"
              :pr="[isImageOnRight(index) ? '0' : '20px', '0']"
            >
              <c-text
                :font-size="['28px', '32px']"
                color="#FFFFFF"
                font-weight="700"
                font-family="Roboto"
              >
                {{ index + 1 }}
              </c-text>
              <c-text
                :font-size="['14px', '16px']"
                color="#FFFFFF"
                font-family="Roboto"
                :px="['0', '20px']"
                :text-align="[isImageOnRight(index) ? 'left' : 'right', 'center']"
              >
                {{ item.name }}
              </c-text>
            </c-flex>
          </c-flex>
        </c-box>
      </c-flex>
      <c-flex
        display="none"
        flex-direction="column"
        justify-content="center"
        align-items="center"
      >
        <c-box 
          h="1px"
          :w="['220px', '200px']"
          background-color="#888888"
          :my="['24px', '16px']"
        />
        <c-text
          color="#008C81"
          font-family="Roboto"
          :font-size="['20px', '24px']"
          font-weight="700"
        >
          Coba Konsultasi 30 Menit
        </c-text>
        <c-text
          color="#008C81"
          font-family="Roboto"
          :font-size="['28px', '30px']"
          font-weight="700"
        >
          GRATIS
        </c-text>
        <c-box 
          h="1px"
          :w="['220px', '200px']"
          background-color="#888888"
          :my="['24px', '16px']"
        />
      </c-flex>
      <c-button
        v-chakra="{
          ':hover': {
            background: '#005A64',
          },
        }"
        as="router-link"
        :to="{ name: 'client.select-program' }"
        :width="['auto', '290px']"
        flex-direction="row"
        justify-content="center"
        align-items="center"
        :border-radius="['24px', '36px']"
        :px="['16px', '56px']"
        :py="['8px', '16px']"
        :height="['40px', 'auto']"
        background="#008C81"
        color="#FFFFFF"
      >
        <c-text
          :font-size="['16px', '18px']"
          :mr="['8px', '0px']"
          font-weight="500"
          font-family="Roboto"
        >
          Coba Sekarang
        </c-text>
        <c-image 
          :d="['block', 'none']"
          :src="require('@/assets/ic-arrow-right-white-alt.svg')" 
          width="24px" 
          height="24px"
        />
      </c-button>
    </c-flex>
  </c-box>
</template>

<script>

export default {
  name: 'ServiceStage',
  components: {
  },
  data() {
    return {
      stages: [
        {
          name: 'Pilih dan bayar layanan',
          image: 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/tahapan1.webp',
          right: '-30px',
          top: '-60px',
        },
        {
          name: 'Terhubung ke ahli gizi dan pelatih kebugaran',
          image: 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/tahapan2.webp',
          right: '-40px',
          top: '-55px',
        },
        {
          name: 'Dapat personalized meal plan',
          image: 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/tahapan3.webp',
          right: '-73px',
          top: '-50px',
        },
        {
          name: 'Nikmati perjalanan sehatmu',
          image: 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/tahapan4.webp',
          right: '-55px',
          top: '-32px',
        },
      ],
    }
  },
  methods: {
    isImageOnRight(index) {
      return index % 2 == 0 ? true : false 
    },
  },
}
</script>

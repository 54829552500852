var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "position": "relative",
      "z-index": "10",
      "margin-top": [_vm.isAuthenticated ? '0px' : '36px', _vm.isAuthenticated ? '0px' : '32px'],
      "background": _vm.isAuthenticated ? ['linear-gradient(180deg, rgba(0, 140, 130, 1) 0%, rgba(242, 242, 242, 1) 90%)', 'linear-gradient(180deg, rgba(0, 140, 130, 1) 0%, rgba(255, 255, 255, 0.2) 80%)'] : 'unset',
      "border-top-left-radius": _vm.isAuthenticated ? ['50px', '150px'] : '0px'
    }
  }, [_c('c-flex', {
    attrs: {
      "position": "relative",
      "background-color": "transparent",
      "w": "100%",
      "max-width": "1100px",
      "justify-content": "center",
      "align-items": "center",
      "margin-bottom": "1rem",
      "mx": "auto",
      "z-index": "2"
    }
  }, [_c('c-flex', {
    attrs: {
      "id": "list-program",
      "flex-direction": "column",
      "w": "100%",
      "justify-content": "center",
      "align-content": "center",
      "p": "1rem",
      "pos": "relative"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '28px'],
      "font-weight": "600",
      "color": "#000000",
      "mb": ['8px', '1.5rem'],
      "align": "center",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Program dan Layanan Dietela ")]), _vm.productCategory.length > 0 ? _c('c-grid', {
    attrs: {
      "w": ['100%', '80%'],
      "template-columns": "repeat(".concat(_vm.productCategory.length > 4 ? 4 : _vm.productCategory.length, ", 1fr)"),
      "mx": "auto",
      "gap": "6px",
      "align-items": ['flex-start', 'center']
    }
  }, _vm._l(_vm.productCategory, function (menu, index) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            color: '#FFFFFF',
            backgroundColor: '#005A64',
            transitionDuration: '300ms'
          },
          '&.active, &:focus, &:active': {
            color: '#FFFFFF',
            backgroundColor: '#008C81',
            width: ['100%', '220px'],
            height: ['80px', '180px'],
            flexDirection: ['column-reverse', 'column-reverse']
          }
        },
        expression: "{\n            ':hover': {\n              color: '#FFFFFF',\n              backgroundColor: '#005A64',\n              transitionDuration: '300ms',\n            },\n            '&.active, &:focus, &:active': {\n              color: '#FFFFFF',\n              backgroundColor: '#008C81',\n              width: ['100%', '220px'],\n              height: ['80px', '180px'],\n              flexDirection: ['column-reverse', 'column-reverse'],\n            },\n          }"
      }],
      key: index,
      class: {
        active: menu.id == _vm.active
      },
      attrs: {
        "d": "flex",
        "height": ['67px', '160px'],
        "width": ['100%', '160px'],
        "mx": "auto",
        "flex-direction": ['column-reverse', 'column'],
        "jusity-content": "center",
        "align-items": "center",
        "border-radius": "12px",
        "p": ['5px', '20px'],
        "box-shadow": ['0px 2px 2px 0px #008C8126', '0px 4px 8px rgba(0, 0, 0, 0.2)'],
        "cursor": "pointer",
        "color": ['#111111', '#008C81'],
        "background-color": "#FFFFFF",
        "white-space": "pre-warp",
        "gap": ['8px', '16px']
      },
      on: {
        "click": function click($event) {
          return _vm.selectMenu(menu, index < _vm.centerCategoryProgramIndex ? 'left' : 'right');
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['10px', '16px'],
        "font-family": "Roboto"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")]), _c('c-image', {
      attrs: {
        "src": menu.icon,
        "w": ['50px', '120px'],
        "border-radius": ['4px', '12px']
      }
    })], 1);
  }), 1) : _vm._e()], 1)], 1), _vm.products ? _c('c-flex', {
    attrs: {
      "w": "100%",
      "max-width": "1100px",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "margin-top": ['24px', '32px'],
      "margin-bottom": "16px",
      "mx": "auto",
      "px": ['1rem', '0px'],
      "gap": "16px"
    }
  }, [_c('SkeletonProgram', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoadingCard,
      expression: "isLoadingCard"
    }]
  }), _vm._l(_vm.products, function (product, index) {
    return _c('CardProgram', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.isLoadingCard,
        expression: "!isLoadingCard"
      }],
      key: index,
      attrs: {
        "data": product,
        "loading": _vm.isLoadingButton,
        "align": _vm.cardAlign
      },
      on: {
        "on-click": _vm.openDetailProduct
      }
    });
  })], 2) : _vm._e(), _c('Portal', {
    attrs: {
      "to": "program-recommendation"
    }
  }, [!_vm.isAuthenticated ? _c('RecommendationProgram', {
    attrs: {
      "product-slug": _vm.firstProductSlug,
      "category-id": _vm.active,
      "product-duration": 30
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <c-box>
    <!-- Artikel -->
    <c-box
      :w="['100%', '80%']"
      max-width="1100px"
      justify-content="center"
      align-items="center"
      pb="1rem"
      mx="auto"
    >
      <c-flex
        flex-direction="column"
        w="100%"
        justify-content="center"
        align-content="center"
        :px="['16px', '0px']"
        pos="relative"
      >
        <c-text
          :font-size="['16px', '28px']"
          :font-weight="['500', '700']"
          color="#000000"
          :mb="['6px', '6px']"
          font-family="Roboto"
        >
          Artikel Terbaru
        </c-text>
        <c-flex
          flex-direction="row"
          justify-content="space-between"
          :mb="['6px', '6px']"
          align-items="center"
        >
          <c-flex
            :gap="['6px', '10px']"
            flex-direction="row"
          >
            <c-button
              v-for="(menu, index) in categories"
              :key="index"
              v-chakra="{
                ':hover': {
                  backgroundColor: 'transparent',
                },
                '&.active, &:focus, &:active': {
                  backgroundColor: '#C7F9E3',
                  border: '2px solid #008C81',
                  borderRadius: '30px',
                  '@media(max-width: 767px)': {
                    border: '1px solid #008C81',
                  },
                },
              }"
              :class="{ active: menu.id == active }"
              font-weight="400"
              font-family="Roboto"
              :font-size="['12px', '16px']"
              color="#008C81"
              :h="['fit-content', 'auto']"
              :py="['6px', '10px']"
              :px="['6px', '16px']"
              align="center"
              background-color="transparent"
              @click="selectMenu(menu)"
            >
              {{ menu.name }}
            </c-button>
          </c-flex>
          <c-link
            v-chakra="{
              ':hover': {
                backgroundColor: 'transparent',
              },
              ':focus': {
                outline: 'none',
                boxShadow: 'none !important',
                background: 'none !important',
              },
            }"
            color="#008C81"
            :font-size="['12px', '16px']"
            font-weight="400"
            font-family="Roboto"
            as="a"
            :href="`${ENV.URL_DIETELA_WORDPRESS}/blog/`"
          >
            Lihat Semua
          </c-link>
        </c-flex>
      </c-flex>
      <c-box>
        <VueSlickCarousel 
          v-if="post.length > 0" 
          v-chakra="{
            '.slick-slide': {
              padding: '10px 10px',
              '@media(max-width: 767px)': {
                padding: '0 0 0 10px',
              }
            }
          }"
          v-bind="settings"
        >
          <c-box
            v-for="(item, index) in post"
            :key="index"
            bg-color="white"
            border-radius="12px"
            overflow="hidden"
            as="a"
            :p="['5px', '10px']"
            :box-shadow="['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)']"
            :href="item.link || ''"
            :max-w="['300px', '100%']"
          >
            <c-box
              :h="['54px', '62px']"
              bg-color="#008C81"
              :border-radius="['6px 6px 20px 20px', '8px 8px 30px 30px']"
              :mb="['5px', '10px']"
              :p="['4px 0px', '4px 10px']"
            >
              <c-text
                :font-size="['16px', '18px']"
                :font-weight="['400', '500']"
                px="6px"
                font-family="Roboto"
                align="center"
                color="white"
                overflow="hidden"
              >
                {{ splitServiceTarget(item.title.rendered) }}
              </c-text>
            </c-box>
            <CardArtikel :data="`${ENV.URL_DIETELA_WORDPRESS}/wp-json/wp/v2/media?parent=${item.id}`" />
          </c-box>
        </VueSlickCarousel>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import CardArtikel from '@/components/artikel/card-artikel.vue'
import { ENV } from '@/constants/config'

export default {
  name: 'ArtikelComp',
  components: {
    VueSlickCarousel,
    CardArtikel,
  },
  data() {
    return {
      ENV,
      active: '',
      isLoadingCard: false,
      isOpenModal: false,
      isLoadingButton: false,
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        draggable: false,
        swipeToSlide: false,
        touchMove: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              draggable: true,
              swipeToSlide: true,
              touchMove: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: true,
            },
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      categories: 'general/categories',
      post: 'general/post',
    }),
  },
  async mounted() {
    await this.loadCategories()
    if (this.categories.length > 0) {
      this.selectMenu(this.categories[0])
    }
  },
  methods: {
    ...mapActions({
      loadCategories: 'general/getCategories',
      listPost: 'general/getPost',
    }),
    async loadPost() {
      await this.listPost(this.active)
    },
    selectMenu(menu) {
      if (this.active != menu.id) {
        this.active = menu.id
        this.loadPost()
      }
    },
    splitServiceTarget(str) {
      const regex = /<[^>]+>/g
      const convert = str.replace(regex, '')
      return convert
    },
  },
}
</script>

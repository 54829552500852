<template>
  <c-box
    max-w="1100px"
    mx="auto"
    w="100%"
    pb="16px"
  >
    <c-box :mx="['20px', '0']">
      <c-text
        align="center"
        :font-weight="['500','700']"
        :font-size="['16px','28px']"
        color="black"
        font-family="Roboto"
      >
        FAQ
      </c-text>
      <c-accordion
        v-if="faq.length > 0"
        :default-index="[]"
        :allow-multiple="false"
        :allow-toggle="true"
        d="grid"
        :grid-template-columns="['1fr', '1fr 1fr']"
        mx="auto"
        gap="8px"
        :px="['0px', '30px']"
        :py="['12px', '18px']"
      >
        <c-accordion-item
          v-for="(item,index) in faq"
          v-slot="{ isExpanded }"
          :key="index"
          v-chakra="{
            ':hover': {
              backgroundColor: 'white',
            },
            ':focus': {
              outline: 'none',
              boxShadow: 'none !important',
              background: 'white !important',
            },
          }"
          w="100%"
          bg="white"
          border-radius="8px"
          h="fit-content"
          font-family="Roboto"
        >
          <c-accordion-header
            v-chakra="{
              ':hover': {
                backgroundColor: 'white',
                borderRadius: '8px',
              },
              ':focus': {
                outline: 'none',
                boxShadow: 'none !important',
                background: 'white !important',
                borderRadius: '8px',
              },
            }"
            :h="['auto','64px']"
          >
            <c-box
              flex="1"
              text-align="left"
              :font-size="['14px', '16px']"
              :font-weight="['400']"
              color="#000000"
              pr="20px"
            >
              {{ (index+1) + '. ' + item.question }}
            </c-box>
            <c-image
              :src="require('@/assets/icon-accordion.svg')"
              :h="['10px']"
              :w="['5px']"
              :transform="!isExpanded ? 'rotate(0)' : 'rotate(-90deg)'"
              animation="1s transform ease"
            />
          </c-accordion-header>
          <c-accordion-panel
            pb="4"
            :font-size="['14px', '16px']"
            :font-weight="['400']"
            color="#000000"
            text-align="justify"
            font-family="Roboto"
          >
            <c-text
              v-for="(item_, index_) in splitString(
                item.answer
              )"
              :key="index_"
              font-family="Roboto"
            >
              <c-text
                font-family="Roboto"
                v-html="item_ === '' ? '<br>': item_"
              />
            </c-text>
          </c-accordion-panel>
        </c-accordion-item>
      </c-accordion>
    </c-box>
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FAQ',
  computed: {
    ...mapGetters({
      faq: 'general/faq',
    }),
  },
  async mounted() {
    this.loadFaq()
  },
  methods: {
    ...mapActions({
      loadFaq: 'general/getFaq',
    }),
    splitString(string) {
      if (!string) return ''
      return string.split('\r\n')  
    },
  },
}
</script>
<template>
  <c-box
    max-w="1100px"
    mx="auto"
    w="100%"
    pb="1rem"
  >
    <c-flex
      bg="#F1E456"
      :border-radius="['4px 4px 40px 4px', '4px 4px 100px 40px']"
      flex-direction="rows"
      :py="['12px', '67px']"
      :pr="['16px', '50px']"
      :pl="['0', '130px']"
      justify-content="space-between"
      :mx="['16px', '0']"
      isolation="isolate"
      :overflow="['unset','hidden']"
    >
      <c-flex
        flex-direction="column"
        align="start"
        :gap="['5px', '20px']"
        :ml="['20px','-50px']"
      >
        <c-text
          font-family="Roboto"
          :font-size="['18px', '28px']"
          :font-weight="['700']"
          color="#008C81"
          text-align="right"
        >
          Konsultasi Sekarang
        </c-text>
        <c-flex
          flex-direction="rows"
          :px="['10px', '85.5px']"
          :py="['5px', '15px']"
          align-items="center"
          :gap="['10px']"
          align="end"
          bg="#008C81"
          :border-radius="['16px', '58px']"
          w="fit-content"
          as="a"
          :href="'https://wa.me/6281213734726'"
          target="_blank"
        >
          <c-text
            font-family="Roboto"
            :font-weight="['500']"
            :font-size="['14px', '18px']"
            color="white"
          >
            Chat Dietela
          </c-text>
          <c-image
            :src="require('@/assets/icon-konsultasi.svg')"
            :h="['24px', '30px']"
            :w="['24px', '30px']"
          />
        </c-flex>
      </c-flex>
      <c-box>
        <c-box
          mt="0px"
          mb="-250px"
          ml="-120px"
          :display="['none', 'block']"
        >
          <svg
            width="500"
            height="251"
            viewBox="0 0 500 251"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="250"
              cy="250"
              r="250"
              fill="#F4CC46"
            />
          </svg>
        </c-box>
        <c-image
          :src="'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/nutritionist_3.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676455017704'"
          :h="['100px', '250px']"
          :mt="['-18px', '0']"
          :mb="['-12px', '-67px']"
        />
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
export default {
  name: 'KonsultasiAdmin',
}
</script>

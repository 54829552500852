<template>
  <c-box
    :w="['100%', '75%']"
    max-width="800px"
    mx="auto"
    gap="6px"
  >
    <c-flex
      position="relative"
      w="100%"
      :min-height="['155px', '255px']"
      :flex-direction="['row', align == 'left' ? 'row-reverse' : 'row']"
      :justify-content="['flex-start', 'center']"
      :align-items="['normal', 'flex-end']"
      background-color="#00A68C"
      :box-shadow="[
        '0px 2px 4px rgba(0, 0, 0, 0.1)',
        '0px 4px 12px rgba(0, 0, 0, 0.2)',
      ]"
      border-radius="12px"
      :p="['16px 10px 10px 10px', '20px 30px']"
      :gap="['0px', '10px']"
    >
      <c-image
        :src="data?.photoLandingPage"
        pos="absolute"
        :display="['block', 'none']"
        top="calc(-100% + 97px)"
        :right="align == 'left' ? '16px' : 'unset'"
        :left="align == 'left' ? 'unset' : '16px'"
        height="98px"
        border-radius="10px"
        :transform="align == 'left' ? 'scaleX(-1)' : ''"
        z-index="2"
      />
      <c-flex
        :display="['none', 'flex']"
        pos="relative"
        flex-grow="1"
        height="100%"
      >
        <c-image
          :src="data.photoLandingPage"
          pos="absolute"
          bottom="0"
          :w="['100px', '250px']"
          border-radius="10px"
          margin-left="24px"
          :transform="align == 'left' ? 'scaleX(-1)' : ''"
        />
      </c-flex>
      <c-flex
        flex-direction="column"
        justify-content="space-between"
        align-items="center"
        :width="['100%', '440px']"
      >
        <c-text
          width="100%"
          color="#FFF"
          :font-size="['16px', '28px']"
          :mb="['8px', '16px']"
          font-weight="500"
          font-family="Roboto"
          :text-align="[align, 'center']"
        >
          {{ data.name }}
        </c-text>
        <c-text
          width="100%"
          color="#FFF"
          :font-size="['12px', '18px']"
          :text-align="['left', 'justify']"
          :px="['0px', '16px']"
          :mb="['8px', '16px']"
          :font-weight="['400', '500']"
          font-family="Roboto"
        >
          {{ data.overview }}
        </c-text>
        <c-button
          v-chakra="{
            ':hover': {
              backgroundColor: '#CFC13E',
              color: '#005A64',
            },
          }"
          d="flex"
          flex-direction="row"
          justify-content="center"
          align-items="center"
          :width="['auto', '290px']"
          :border-radius="['24px', '60px']"
          :py="['8px', '12px']"
          :height="['32px', '60px']"
          background="secondary.400"
          color="primary.400"
          :is-loading="loading"
          loading-text="Memuat Data"
          font-family="Roboto"
          :font-size="['14px', '18px']"
          font-weight="500"
          gap="16px"
          @click="$emit('on-click', data)"
        >
          <c-text
            :font-size="['14px', '18px']"
            font-weight="500"
            font-family="Roboto"
          >
            Lihat Layanan
          </c-text>
          <c-box
            :width="['16px', '20px']"
            :height="['16px', '20px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-right.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
            />
          </c-box>
        </c-button>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
export default {
  name: 'CardProgram', 
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'left',
    },
  },
  emits: ['on-click'],
}
</script>

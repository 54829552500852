var render = function () {
  var _vm$product;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.getIsShowProduct ? _c('c-box', {
    attrs: {
      "position": "relative",
      "w": ['100%', '73%'],
      "mx": "auto",
      "z-index": "11"
    }
  }, [_c('c-text', {
    attrs: {
      "display": "block",
      "font-family": "Roboto",
      "font-weight": ['600', '700'],
      "font-size": ['14px', '28px'],
      "mt": ['1.5rem', '1rem'],
      "mb": ['8px', '1rem'],
      "text-align": "center"
    }
  }, [_vm._v(" Pilihan Program " + _vm._s((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.name) + " sesuai kebutuhanmu ")]), _vm.productServices.length > 0 ? _c('c-box', {
    staticClass: "tableGsap",
    attrs: {
      "width": "100%",
      "max-width": "1100px",
      "px": ['10px', '0'],
      "pb": "1rem",
      "mx": "auto"
    }
  }, [_c('c-box', {
    staticClass: "table-recommendation",
    attrs: {
      "pos": "relative",
      "display": ['none', 'table'],
      "as": "table",
      "width": "100%",
      "z-index": "3"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "thead",
      "color": "#FFF",
      "background-color": "primary.400",
      "font-weight": "700",
      "border-radius": "8px"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:first-child': {
          borderLeft: '1px solid #008C81'
        },
        '>:last-child': {
          borderRight: '1px solid #008C81'
        },
        '>:not(:last-child):not(:first-child)': {
          borderRight: '1px solid #005A64',
          borderLeft: '1px solid #005A64'
        }
      },
      expression: "{\n            '>:first-child': {\n              borderLeft: '1px solid #008C81',\n            },\n            '>:last-child': {\n              borderRight: '1px solid #008C81',\n            },\n            '>:not(:last-child):not(:first-child)': {\n              borderRight: '1px solid #005A64',\n              borderLeft: '1px solid #005A64',\n            },\n          }"
    }],
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "th",
      "text-align": "center",
      "font-size": "24px",
      "py": "1rem"
    }
  }, [_vm._v(" Fasilitas Layanan ")]), _vm._l(_vm.productServices, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "as": "th",
        "flex-direction": "column",
        "justify": "center",
        "py": "1rem",
        "width": "250px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "20px",
        "margin-bottom": "8px",
        "font-weight": "700"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.programsService) + " " + _vm._s(item === null || item === void 0 ? void 0 : item.duration) + " Hari ")]), _c('c-text', {
      attrs: {
        "font-size": "32px"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item === null || item === void 0 ? void 0 : item.price, 2)) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "400"
      }
    }, [_vm._v(" (Rp " + _vm._s(_vm.pricePerDay(item === null || item === void 0 ? void 0 : item.price)) + " ribuan/hari) ")])], 1);
  })], 2)], 1), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> tr': {
          borderLeft: '1px solid #008C81',
          borderRight: '1px solid #008C81'
        }
      },
      expression: "{\n          '> tr': {\n            borderLeft: '1px solid #008C81',\n            borderRight: '1px solid #008C81',\n          },\n        }"
    }],
    attrs: {
      "as": "tbody",
      "background-color": "#FFF",
      "color": "#000",
      "font-size": ['12px', '16px']
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:not(:last-child):not(:first-child)': {
          borderRight: '1px solid #C4C4C4',
          borderLeft: '1px solid #C4C4C4'
        }
      },
      expression: "{\n            '>:not(:last-child):not(:first-child)': {\n              borderRight: '1px solid #C4C4C4',\n              borderLeft: '1px solid #C4C4C4',\n            },\n          }"
    }],
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }), _vm._l(_vm.productServices, function (item, idx) {
    return _c('c-box', {
      key: "sub-".concat(idx),
      attrs: {
        "as": "td",
        "w": "fit-content",
        "p": "20px 10px 10px 10px"
      }
    }, [_c('c-text', {
      attrs: {
        "background-color": "secondary.50",
        "color": "primary.400",
        "font-size": "14px",
        "font-weight": "400",
        "text-align": "center",
        "padding": "6px"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.description) + " ")])], 1);
  })], 2), _vm._l(_vm.facilities, function (facility, i) {
    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '>:not(:last-child):not(:first-child)': {
            borderRight: '1px solid #C4C4C4',
            borderLeft: '1px solid #C4C4C4'
          }
        },
        expression: "{\n            '>:not(:last-child):not(:first-child)': {\n              borderRight: '1px solid #C4C4C4',\n              borderLeft: '1px solid #C4C4C4',\n            },\n          }"
      }],
      key: "tr-".concat(i),
      attrs: {
        "as": "tr"
      }
    }, _vm._l(_vm.getTotalProductService + 1, function (k) {
      var _vm$productServices, _vm$productServices2, _vm$productServices3, _vm$productServices4;

      return _c('c-box', {
        key: "td-".concat(i, "-").concat(k),
        attrs: {
          "as": "td",
          "px": "1rem",
          "text-align": k === 1 ? 'left' : 'center'
        }
      }, [k === 1 ? _c('c-box', {
        attrs: {
          "w": "100%",
          "py": "1rem",
          "px": k === 1 ? '12px' : '0',
          "border-bottom": "1px solid #C4C4C4"
        }
      }, [_vm._v(" " + _vm._s(facility) + " ")]) : _c('c-box', {
        attrs: {
          "w": "100%",
          "py": "1rem",
          "border-bottom": "1px solid #C4C4C4",
          "display": "flex",
          "justify-content": "center"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility, (_vm$productServices = _vm.productServices[k - 2]) === null || _vm$productServices === void 0 ? void 0 : _vm$productServices.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$productServices2 = _vm.productServices[k - 2]) === null || _vm$productServices2 === void 0 ? void 0 : _vm$productServices2.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$productServices3 = _vm.productServices[k - 2]) === null || _vm$productServices3 === void 0 ? void 0 : _vm$productServices3.productFacilitiesComparation)) ? '#008C81' : '#D32737'
        }
      }) : _c('c-box', [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility, (_vm$productServices4 = _vm.productServices[k - 2]) === null || _vm$productServices4 === void 0 ? void 0 : _vm$productServices4.productFacilitiesComparation)) + " ")])], 1)], 1);
    }), 1);
  }), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:not(:last-child):not(:first-child)': {
          borderRight: '1px solid #C4C4C4',
          borderLeft: '1px solid #C4C4C4'
        }
      },
      expression: "{\n            '>:not(:last-child):not(:first-child)': {\n              borderRight: '1px solid #C4C4C4',\n              borderLeft: '1px solid #C4C4C4',\n            },\n          }"
    }],
    attrs: {
      "as": "tr",
      "border-bottom": "1px solid #008C81"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "text-align": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "max-width": "270px",
      "width": "100%",
      "variant": "outline",
      "variant-color": "primary",
      "border-radius": "60px",
      "height": "50px"
    },
    on: {
      "click": function click($event) {
        var _vm$product2;

        $event.preventDefault();
        return _vm.onClickMoreInfo((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.slug);
      }
    }
  }, [_vm._v(" Info Lebih Lengkap ")])], 1), _vm._l(_vm.productServices, function (item) {
    return _c('c-box', {
      key: "buy-".concat(item === null || item === void 0 ? void 0 : item.id),
      attrs: {
        "as": "td",
        "px": "1rem",
        "py": "1rem"
      }
    }, [_c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            color: 'primary.500',
            background: 'secondary.500'
          },
          ':active': {
            color: 'white',
            background: 'secondary.600'
          }
        },
        expression: "{\n                ':hover': {\n                  color:'primary.500',\n                  background:'secondary.500',\n                },\n                ':active': {\n                  color: 'white',\n                  background: 'secondary.600',\n                },\n              }"
      }],
      attrs: {
        "width": "100%",
        "variant": "solid",
        "color": "primary.400",
        "background": "secondary.400",
        "border-radius": "60px",
        "height": "50px"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.handleSelectProgram({
            productId: item === null || item === void 0 ? void 0 : item.productId,
            productServiceId: item === null || item === void 0 ? void 0 : item.id,
            duration: _vm.productDuration
          });
        }
      }
    }, [_vm._v(" Beli Program ")])], 1);
  })], 2)], 2)], 1), _c('c-box', {
    attrs: {
      "pos": "relative",
      "display": ['block', 'none'],
      "z-index": "3",
      "width": "100%"
    }
  }, [_c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:first-child) > div': {
          borderLeft: '1px solid #005A64'
        }
      },
      expression: "{\n          '> :not(:first-child) > div': {\n            borderLeft:'1px solid #005A64',\n          },\n        }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(_vm.getTotalProductService, ", 1fr)"),
      "background-color": "primary.400",
      "color": "#FFF",
      "font-size": "14px",
      "font-weight": "500",
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px",
      "border": "1px solid #008C81"
    }
  }, _vm._l(_vm.productServices, function (item) {
    return _c('c-box', {
      key: item.programsService,
      attrs: {
        "w": "100%",
        "py": "6px"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "flex-direction": "column",
        "align-items": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "8px"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.programsService) + " " + _vm._s(item === null || item === void 0 ? void 0 : item.duration) + " Hari ")]), _c('c-text', {
      attrs: {
        "font-size": "16px"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item === null || item === void 0 ? void 0 : item.price, 2)) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "10px",
        "font-weight": "400"
      }
    }, [_vm._v(" (Rp " + _vm._s(_vm.pricePerDay(item === null || item === void 0 ? void 0 : item.price)) + " ribuan/hari) ")])], 1)], 1);
  }), 1), _c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:first-child)': {
          borderLeft: '1px solid #9CD8FC'
        }
      },
      expression: "{\n          '> :not(:first-child)': {\n            borderLeft:'1px solid #9CD8FC',\n          },\n        }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(_vm.getTotalProductService, ", 1fr)"),
      "background-color": "secondary.50",
      "border-left": "1px solid #008C81",
      "border-right": "1px solid #008C81"
    }
  }, _vm._l(_vm.productServices, function (item, idx) {
    return _c('c-box', {
      key: "sub-".concat(idx),
      attrs: {
        "w": "100%",
        "p": "6px",
        "color": "primary.400",
        "font-size": "12px",
        "font-weight": "400",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.description) + " ")]);
  }), 1), _c('c-box', {
    attrs: {
      "p": "8px",
      "color": "#000",
      "font-size": "12px",
      "font-weight": "400",
      "background-color": "#FFF",
      "border-bottom-left-radius": "8px",
      "border-bottom-right-radius": "8px",
      "border-left": "1px solid #008C81",
      "border-right": "1px solid #008C81",
      "border-bottom": "1px solid #008C81"
    }
  }, [_vm._l(_vm.facilities, function (facility, index) {
    return _c('c-box', {
      key: "facility-".concat(index),
      attrs: {
        "mb": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "p": "6px 12px",
        "background-color": "primary.50",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(facility) + " ")]), _c('c-grid', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '> :not(:first-child)': {
            borderLeft: '1px solid #C4C4C4'
          }
        },
        expression: "{\n              '> :not(:first-child)': {\n                borderLeft:'1px solid #C4C4C4',\n              },\n            }"
      }],
      attrs: {
        "w": "100%",
        "template-columns": "repeat(".concat(_vm.getTotalProductService, ", 1fr)")
      }
    }, _vm._l(_vm.getTotalProductService, function (j) {
      var _vm$productServices5, _vm$productServices6, _vm$productServices7, _vm$productServices8;

      return _c('c-flex', {
        key: j,
        attrs: {
          "w": "100%",
          "py": "4px",
          "flex-direction": "column",
          "align-items": "center"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility, (_vm$productServices5 = _vm.productServices[j - 1]) === null || _vm$productServices5 === void 0 ? void 0 : _vm$productServices5.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$productServices6 = _vm.productServices[j - 1]) === null || _vm$productServices6 === void 0 ? void 0 : _vm$productServices6.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$productServices7 = _vm.productServices[j - 1]) === null || _vm$productServices7 === void 0 ? void 0 : _vm$productServices7.productFacilitiesComparation)) ? '#008C81' : '#D32737'
        }
      }) : _c('c-box', [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility, (_vm$productServices8 = _vm.productServices[j - 1]) === null || _vm$productServices8 === void 0 ? void 0 : _vm$productServices8.productFacilitiesComparation)) + " ")])], 1);
    }), 1)], 1);
  }), _c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:first-child)': {
          borderLeft: '1px solid #F2F2F2'
        }
      },
      expression: "{\n            '> :not(:first-child)': {\n              borderLeft:'1px solid #F2F2F2',\n            },\n          }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(_vm.getTotalProductService, ", 1fr)")
    }
  }, _vm._l(_vm.productServices, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "p": "6px"
      }
    }, [_c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            color: 'primary.500',
            background: 'secondary.500'
          },
          ':active': {
            color: 'white',
            background: 'secondary.600'
          }
        },
        expression: "{\n                ':hover': {\n                  color:'primary.500',\n                  background:'secondary.500',\n                },\n                ':active': {\n                  color: 'white',\n                  background: 'secondary.600',\n                },\n              }"
      }],
      attrs: {
        "width": "100%",
        "variant": "solid",
        "color": "primary.400",
        "background": "secondary.400",
        "border-radius": "16px",
        "height": "34px",
        "font-size": "12px",
        "px": "0"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.handleSelectProgram({
            productId: item === null || item === void 0 ? void 0 : item.productId,
            productServiceId: item === null || item === void 0 ? void 0 : item.id,
            duration: _vm.productDuration
          });
        }
      }
    }, [_vm._v(" Beli Program ")])], 1);
  }), 1), _c('c-box', {
    attrs: {
      "p": "6px"
    }
  }, [_c('c-button', {
    attrs: {
      "width": "100%",
      "variant": "outline",
      "variant-color": "primary",
      "border-radius": "50px",
      "height": "34px",
      "font-size": "12px"
    },
    on: {
      "click": function click($event) {
        var _vm$product3;

        $event.preventDefault();
        return _vm.onClickMoreInfo((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.slug);
      }
    }
  }, [_vm._v(" Info Lebih Lengkap ")])], 1)], 2)], 1)], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
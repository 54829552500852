import { render, staticRenderFns } from "./recommendation.vue?vue&type=template&id=2995a20c&scoped=true&"
import script from "./recommendation.vue?vue&type=script&lang=js&"
export * from "./recommendation.vue?vue&type=script&lang=js&"
import style0 from "./recommendation.vue?vue&type=style&index=0&id=2995a20c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2995a20c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CGrid: require('@chakra-ui/vue').CGrid})

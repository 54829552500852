<template>
  <c-box
    pos="relative"
    w="100%"
    max-width="1100px"
    d="flex"
    justify-content="center"
    align-items="center"
    :mt="['0', '16px']"
    mb="1rem"
    mx="auto"
    z-index="2"
  >
    <c-box
      pos="absolute"
      :top="['-12px', '-24px']"
      background-color="#F5ED7E"
      :w="['75%', 'fit-content']"
      border-radius="8px"
      align="center"
      :p="['5px', '10px']"
    >
      <c-text
        color="#005A64"
        :font-size="['16px', '20px']"
        font-weight="bold"
        font-family="roboto"
      >
        Dietela Sudah Terbukti
      </c-text>
    </c-box>
    <c-box
      background-color="#FAF59A"
      w="100%"
      :p="['2rem 1rem 1rem 1rem', '3rem 3rem 2rem 3rem']"
    >
      <c-grid
        v-if="miniDashboard && miniDashboard.length > 0"
        :w="['100%', '90%']"
        :template-columns="`repeat(${miniDashboard.length > 4 ? 4 : miniDashboard.length}, 1fr)`"
        gap="2"
        mx="auto"
      >
        <c-box
          v-for="(item, index) in miniDashboard"
          :key="index"
          w="100%"
          d="flex"
          flex-direction="column"
          align-items="center"
          align="center"
        >
          <c-text
            color="#005A64"
            :font-size="['16px', '22px']"
            font-weight="600"
            margin-bottom="4px"
            font-family="Roboto"
          >
            {{ item.values }}
          </c-text>
          <c-text
            color="#005A64"
            :font-size="['12px', '16px']"
            font-weight="500"
            font-family="roboto"
          >
            {{ item.statement }}
          </c-text>
        </c-box>
      </c-grid>
    </c-box>
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MiniDashboard',
  computed: {
    ...mapGetters({
      miniDashboard: 'clients/miniDashboard',
    }),
  },
  mounted() {
    this.loadMiniDashboard()
  },
  methods: {
    ...mapActions({
      loadMiniDashboard: 'clients/getMiniDashboard',
    }),
  },
}
</script>

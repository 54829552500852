<template>
  <c-box
    position="relative"
    max-w="1100px"
    mx="auto"
    w="100%"
    pb="1rem"
    z-index="10"
  >
    <c-flex
      bg="#F1E456"
      :border-radius="['4px 4px 4px 40px', '4px 4px 4px 100px']"
      flex-direction="rows"
      :py="['12px', '67px']"
      :pr="['16px', '140px']"
      :pl="['0', '130px']"
      justify-content="space-between"
      :mx="['16px', '0']"
    >
      <c-box>
        <c-box
          mt="-67px"
          mb="-200px"
          ml="-60px"
          :display="['none', 'block']"
        >
          <svg
            width="500"
            height="250"
            viewBox="0 0 500 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="250"
              r="250"
              fill="#F4CC46"
            />
          </svg>
        </c-box>
        <c-image
          :src="'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/nama_icon.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676449921670'"
          :h="['100px', '250px']"
          :mt="['-18px', '0']"
          :mb="['0px', '-67px']"
        />
      </c-box>
      <c-flex
        flex-direction="column"
        align="end"
        :gap="['5px', '20px']"
        :ml="['-50px']"
      >
        <c-text
          font-family="Roboto"
          :font-size="['18px', '28px']"
          :font-weight="['700']"
          color="#008C81"
          text-align="right"
        >
          Dietela Quiz
        </c-text>
        <c-text
          font-family="Roboto"
          :font-size="['12px', '16px']"
          :font-weight="['400']"
          color="#008C81"
          text-align="right"
        >
          Temukan Diet Terbaik Untukmu
        </c-text>
        <c-flex
          flex-direction="rows"
          :px="['10px', '85.5px']"
          :py="['5px', '15px']"
          align-items="center"
          :gap="['10px']"
          align="end"
          bg="#008C81"
          :border-radius="['16px', '58px']"
          w="fit-content"
          as="a"
          :href="ENV.URL_DIETELA_NAMA"
          target="_blank"
        >
          <c-text
            font-family="Roboto"
            :font-weight="['500']"
            :font-size="['14px', '18px']"
            color="white"
          >
            Coba Gratis
          </c-text>
          <c-image
            :src="require('@/assets/icon-nama.svg')"
            :h="['24px', '30px']"
            :w="['24px', '30px']"
          />
        </c-flex>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { ENV } from '@/constants/config'

export default {
  name: 'DietelaQuiz',
  computed: {
    ENV() {
      return ENV
    },
  },
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "max-width": "1100px",
      "d": "flex",
      "justify-content": "center",
      "align-items": "center",
      "mt": ['0', '16px'],
      "mb": "1rem",
      "mx": "auto",
      "z-index": "2"
    }
  }, [_c('c-box', {
    attrs: {
      "pos": "absolute",
      "top": ['-12px', '-24px'],
      "background-color": "#F5ED7E",
      "w": ['75%', 'fit-content'],
      "border-radius": "8px",
      "align": "center",
      "p": ['5px', '10px']
    }
  }, [_c('c-text', {
    attrs: {
      "color": "#005A64",
      "font-size": ['16px', '20px'],
      "font-weight": "bold",
      "font-family": "roboto"
    }
  }, [_vm._v(" Dietela Sudah Terbukti ")])], 1), _c('c-box', {
    attrs: {
      "background-color": "#FAF59A",
      "w": "100%",
      "p": ['2rem 1rem 1rem 1rem', '3rem 3rem 2rem 3rem']
    }
  }, [_vm.miniDashboard && _vm.miniDashboard.length > 0 ? _c('c-grid', {
    attrs: {
      "w": ['100%', '90%'],
      "template-columns": "repeat(".concat(_vm.miniDashboard.length > 4 ? 4 : _vm.miniDashboard.length, ", 1fr)"),
      "gap": "2",
      "mx": "auto"
    }
  }, _vm._l(_vm.miniDashboard, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "w": "100%",
        "d": "flex",
        "flex-direction": "column",
        "align-items": "center",
        "align": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "#005A64",
        "font-size": ['16px', '22px'],
        "font-weight": "600",
        "margin-bottom": "4px",
        "font-family": "Roboto"
      }
    }, [_vm._v(" " + _vm._s(item.values) + " ")]), _c('c-text', {
      attrs: {
        "color": "#005A64",
        "font-size": ['12px', '16px'],
        "font-weight": "500",
        "font-family": "roboto"
      }
    }, [_vm._v(" " + _vm._s(item.statement) + " ")])], 1);
  }), 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <c-box
    :w="['100%', '80%']"
    max-width="1100px"
    mx="auto"
    justify="center"
    align="center"
    :pb="['8px', '1rem']"
  >
    <c-flex
      flex-direction="row"
      justify-content="space-between"
      :px="['16px', '0px']"
      :mb="['5px', '0px']"
      align-items="center"
    >
      <c-text
        font-family="Roboto"
        :font-size="['16px', '28px']"
        :font-weight="['500', '700']"
      >
        Promo & Event Menarik
      </c-text>
      <c-link
        v-if="false"
        color="#008C81"
        :font-size="['12px', '16px']"
        font-weight="400"
        as="a"
        href="#"
        font-family="Roboto"
      >
        Lihat Semua
      </c-link>
    </c-flex>
    <c-box>
      <VueSlickCarousel 
        v-if="promoHighlight.length > 0" 
        v-chakra="{
          '.slick-slide': {
            padding: '10px 10px',
            '@media(max-width: 767px)': {
              padding: '0 0 0 10px',
            }
          }
        }"
        v-bind="settings"
      >
        <c-box
          v-for="(item, index) in promoHighlight"
          :key="index"
          bg-color="white"
          border-radius="12px"
          overflow="hidden"
          as="a"
          :box-shadow="['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)']"
          :max-w="['300px', '100%']"
          @click="handleClick(item)"
        >
          <c-image
            :src="
              item.image
                ? item.image
                : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039'
            "
            alt="dietela"
            w="100%"
          />
        </c-box>
      </VueSlickCarousel>
    </c-box>
  </c-box>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PromoHighlight',
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        draggable: false,
        swipeToSlide: false,
        touchMove: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              draggable: true,
              swipeToSlide: true,
              touchMove: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: true,
            },
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      promoHighlight: 'general/promoHighlight',
    }),
  },
  async mounted() {
    this.loadPromoHighlight()
  },
  methods: {
    ...mapActions({
      loadPromoHighlight: 'general/getPromoHighlight',
    }),
    handleClick(item) {
      const isExternalLink = item.link && item.link.startsWith('http')
      if (isExternalLink && !item.buttonText) {
        window.open(item.link, '_blank')
        return
      }
      
      this.$router.push({
        name: 'client.promo',
        params: { id: item.id },
      })
    },
  },
}
</script>

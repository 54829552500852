<template>
  <c-flex
    flex-direction="row"
    justify-content="space-between"
    align-items="center"
    :pt="['0px', '12px']"
    :pb="['8px', '38px']"
  >
    <c-box
      :d="['none', 'block']"
      :border-radius="['0px', '8px']"
      :box-shadow="['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']"
    >
      <vue-skeleton-loader
        type="rect"
        :width="155"
        :height="324"
        rounded
        animation="fade"
      />
    </c-box>
    <c-box
      d="flex"
      w="100%"
      :mx="['0px', '12px']"
      :border-radius="['0px', '8px']"
      :box-shadow="['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']"
      :height="['200px', '396px']"
      aspect-ratio="16/9"
    >
      <vue-skeleton-loader
        type="rect"
        width="100%"
        height="100%"
        animation="fade"
        rounded
      />
    </c-box>
    <c-box
      :d="['none', 'block']"
      :border-radius="['0px', '8px']"
      :box-shadow="['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']"
    >
      <vue-skeleton-loader
        type="rect"
        :width="155"
        :height="324"
        rounded
        animation="fade"
      />
    </c-box>
  </c-flex>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'  

export default {
  name: 'SkeletonBanner',
  components: { VueSkeletonLoader },
}
</script>

<template>
  <c-box
    pos="relative"
    z-index="10"
  >
    <c-box
      pos="relative"
      w="100%"
      max-width="1100px"
      d="flex"
      flex-direction="column"
      :pb="['36px', '32px']"
      px="0"
      mx="auto"
    >
      <c-text
        font-family="Roboto"
        :font-size="['16px', '28px']"
        color="#111111"
        :px="['16px', '0px']"
        :mb="['5px', '10px']"
        :font-weight="[500, 700]"
      >
        Apa Kata Mereka?
      </c-text>
      <VueSlickCarousel
        v-if="testimonials.length > 0"
        v-chakra="{
          '.slick-slide': {
            padding: '0 0 0 16px',
          },
          '.slick-dots': {
            bottom: '-25px',
            li: {
              '@media(max-width: 767px)': {
                margin: '0 3px',
              },
              button: {
                '::before': {
                  fontSize: '8px',
                  color: '#D9D9D9',
                  opacity: '1',
                  '@media(max-width: 767px)': {
                    fontSize: '10px',
                  },
                },
              },
              '&.slick-active': {
                button: {
                  '::before': {
                    fontSize: '12px',
                    color: '#008C81',
                    opacity: '1',
                  },
                },
              },
            },
          },
        }"
        v-bind="settings"
      >
        <c-box
          v-for="(item, index) in testimonials"
          :key="index"
        >
          <c-image
            :src="item.image"
            :display="['none', 'block']"
            :width="['120px', '130px']"
            :height="['120px', '130px']"
            :border-radius="['60px', '65px']"
            border="4px solid #008C81"
            background-color="#008C81"
            object-fit="cover"
            object-position="50% 20%"
            mx="auto"
          />
          <c-box
            :w="['300px', '320px']"
            :mt="['0', '-65px']"
            background-color="#008C81"
            border-radius="12px"
            px="8px"
            :pt="['8px', '70px']"
            :pb="['42px', '8px']"
            :h="['auto', '320px']"
          >
            <c-flex
              flex-direction="row"
              align-items="center"
              :margin-bottom="['8px', '0']"
            >
              <c-image
                :src="item.image"
                :display="['block', 'none']"
                width="60px"
                height="60px"
                border-radius="60px"
                object-fit="cover"
                object-position="50% 20%"
                margin-right="12px"
              />
              <c-flex
                flex-grow="1"
                :display="['flex', 'block']"
                flex-direction="column"
                font-family="Roboto"
                :font-size="['14px', '16px']"
                color="#FFFFFF"
                mb="8px"
                :text-align="['left', 'center']"
                :font-weight="[400, 600]"
              >
                {{ item.name }} ({{ item.age }}) 
                <c-box
                  as="span"
                  :font-size="['12px', '16px']"
                >
                  {{ item.location }}
                </c-box>
              </c-flex>
            </c-flex>
            <c-box
              font-family="Roboto"
              :font-size="['12px', '14px']"
              color="#FFFFFF"
              :text-align="['left', 'center']"
            >
              {{ item.description }}
            </c-box>
          </c-box>
          <c-box
            :w="['80%', '85%']"
            mx="auto"
            p="4px"
            border="2px solid #008C81"
            border-radius="12px"
            mt="-35px"
            background="#FFFFFF"
            align="center"
          >
            <c-text
              color="#000000"
              :font-size="['12px', '14px']"
              font-family="Roboto"
              mb="6px"
            >
              Program yang digunakan: {{ item.product.name }}
            </c-text>
            <c-button
              v-chakra="{
                ':hover': {
                  backgroundColor: '#005A64',
                },
              }"
              d="flex"
              flex-direction="row"
              justify-content="center"
              align-items="center"
              :border-radius="['24px', '20px']"
              :px="['16px', '12px']"
              :py="['4px', '10px']"
              :height="['32px', 'auto']"
              background-color="#008C81"
              color="#FFFFFF"
              :is-loading="isLoading && (index == selectedIndex)"
              loading-text="Memuat Data"
              font-family="Roboto"
              :font-size="['14px', '14px']"
              font-weight="500"
              @click="handleDetailProgram(item.product)"
            >
              <c-text
                :font-size="['14px', '14px']"
                mr="8px"
                font-weight="500"
                font-family="Roboto"
              >
                Lihat Layanan
              </c-text>
              <c-image
                :src="require('@/assets/ic-arrow-right-white-alt.svg')"
                width="16px"
                height="16px"
              />
            </c-button>
          </c-box>
        </c-box>
      </VueSlickCarousel>
    </c-box>
  </c-box>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { mapActions, mapGetters } from 'vuex'    

export default {
  name: 'TestimonialComp',
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      isLoading: false,
      settings: {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 5000,
        variableWidth: true,
        slidesToScroll: 1,
        pauseOnHover: true,
        pauseOnFocus: true,
        pauseOnDotsHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              adaptiveHeight: true,
            },
          },
        ],
      },
      testimonials: [],
      selectedIndex: '',
    }
  },
  computed: {   
    ...mapGetters({
      product: 'clients/product', 
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      getTestimoni: 'general/getTestimoni',
    }),
    async init() {
      const res = await this.getTestimoni()
      this.testimonials = res.data
    },
    handleDetailProgram(product) {
      this.$router.push({
        name: 'client.program.detail',
        params: {
          id: product?.slug ? product?.slug : product?.id,
        },
      })
    },
  },
}
</script>

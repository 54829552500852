var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "max-width": "1100px",
      "mx": "auto",
      "pb": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "flex-direction": "column",
      "align-items": "center",
      "py": ['16px', '20px'],
      "px": ['16px', '20px'],
      "mx": ['16px', '0px'],
      "background-color": ['#FFFFFF80', '#FFFFFF']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '28px'],
      "color": ['#111111', '#008C81'],
      "mb": ['24px', '64px'],
      "font-weight": ['500', '700'],
      "text-align": ['left', 'center'],
      "w": "100%"
    }
  }, [_vm._v(" Tahapan Memulai Layanan ")]), _c('c-flex', {
    attrs: {
      "flex-direction": ['column', 'row'],
      "w": "100%",
      "justify-content": "space-evenly",
      "px": ['0px', '0px'],
      "mb": ['0', '24px']
    }
  }, _vm._l(_vm.stages, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "mb": ['32px', '0']
      }
    }, [_c('c-flex', {
      attrs: {
        "w": ['100%', '200px'],
        "h": ['82px', '200px'],
        "background-color": "#00A68C",
        "justify-content": "center",
        "align-items": ['flex-end', 'center'],
        "flex-direction": _vm.isImageOnRight(index) ? 'row-reverse' : 'row',
        "pos": "relative",
        "border-top-right-radius": [_vm.isImageOnRight(index) ? '0' : '30px', '100%'],
        "border-bottom-right-radius": [_vm.isImageOnRight(index) ? '0' : '30px', '100%'],
        "border-top-left-radius": [_vm.isImageOnRight(index) ? '30px' : '0', '100%'],
        "border-bottom-left-radius": [_vm.isImageOnRight(index) ? '30px' : '0', '100%']
      }
    }, [_c('c-image', {
      attrs: {
        "pos": ['relative', 'absolute'],
        "w": ['100px', '150px'],
        "h": "auto",
        "object-fit": "contain",
        "object-position": "right bottom",
        "right": ['unset', item.right],
        "top": ['unset', item.top],
        "padding-bottom": ['12px', '0'],
        "src": item.image
      }
    }), _c('c-flex', {
      attrs: {
        "flex-grow": "1",
        "flex-direction": [_vm.isImageOnRight(index) ? 'row' : 'row-reverse', 'column'],
        "align-items": "center",
        "gap": ['1rem', '0'],
        "h": ['100%', 'auto'],
        "pl": [_vm.isImageOnRight(index) ? '20px' : '0', '0'],
        "pr": [_vm.isImageOnRight(index) ? '0' : '20px', '0']
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['28px', '32px'],
        "color": "#FFFFFF",
        "font-weight": "700",
        "font-family": "Roboto"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('c-text', {
      attrs: {
        "font-size": ['14px', '16px'],
        "color": "#FFFFFF",
        "font-family": "Roboto",
        "px": ['0', '20px'],
        "text-align": [_vm.isImageOnRight(index) ? 'left' : 'right', 'center']
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)], 1)], 1);
  }), 1), _c('c-flex', {
    attrs: {
      "display": "none",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "h": "1px",
      "w": ['220px', '200px'],
      "background-color": "#888888",
      "my": ['24px', '16px']
    }
  }), _c('c-text', {
    attrs: {
      "color": "#008C81",
      "font-family": "Roboto",
      "font-size": ['20px', '24px'],
      "font-weight": "700"
    }
  }, [_vm._v(" Coba Konsultasi 30 Menit ")]), _c('c-text', {
    attrs: {
      "color": "#008C81",
      "font-family": "Roboto",
      "font-size": ['28px', '30px'],
      "font-weight": "700"
    }
  }, [_vm._v(" GRATIS ")]), _c('c-box', {
    attrs: {
      "h": "1px",
      "w": ['220px', '200px'],
      "background-color": "#888888",
      "my": ['24px', '16px']
    }
  })], 1), _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          background: '#005A64'
        }
      },
      expression: "{\n        ':hover': {\n          background: '#005A64',\n        },\n      }"
    }],
    attrs: {
      "as": "router-link",
      "to": {
        name: 'client.select-program'
      },
      "width": ['auto', '290px'],
      "flex-direction": "row",
      "justify-content": "center",
      "align-items": "center",
      "border-radius": ['24px', '36px'],
      "px": ['16px', '56px'],
      "py": ['8px', '16px'],
      "height": ['40px', 'auto'],
      "background": "#008C81",
      "color": "#FFFFFF"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "mr": ['8px', '0px'],
      "font-weight": "500",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Coba Sekarang ")]), _c('c-image', {
    attrs: {
      "d": ['block', 'none'],
      "src": require('@/assets/ic-arrow-right-white-alt.svg'),
      "width": "24px",
      "height": "24px"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <c-flex
    v-if="data"
    margin-top="16px"
    padding-inline="8px"
    justify-content="center"
    position="relative"
    z-index="10"
  >
    <c-flex
      width="100%"
      :max-width="['600px', '800px']"
      :border-radius="['8px', '12px']"
      :padding-inline="['12px', '80px']"
      :padding-block="['8px', '10px']"
      background-color="#FFDA45"
      justify-content="space-between"
      align-items="center"
      gap="0px"
    >
      <c-box
        flex-grow="1"
        v-chakra="{
          'p': {
            color: '#003935',
            fontSize: ['10px', '16px'],
            fontWeight: 500,
          },
          'b': {
            fontSize: ['15px', '22px'],
            fontWeight: 700,
          },
        }"
        v-html="data?.title"
      />
      <c-flex
        position="relative"
        flex-shrink="0"
        flex-direction="column"
        justify-content="center"
      >
        <c-image
          v-if="data?.image"
          :src="data?.image"
          :height="['22px', '50px']"
          :margin-bottom="['-1px', '-4px']"
          alt="pedoman jajan"
        />
        <c-button
          variant="solid"
          variant-color="primary"
          :min-width="['120px', '290px']"
          :padding-inline="['12px', '16px']"
          :padding-block="['5px', '10px']"
          :border-radius="['12px' ,'50px']"
          :font-size="['14px', '18px']"
          font-weight="500"
          :height="['24px', '50px']"
          @click="onClickCta"
        >
          {{ data?.cta }}
        </c-button>
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import { reqOther } from '@/requests/dietela-api/general/other'

export default {
  name: 'SnackingGuidance',
  data() {
    return {
      data: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const res = await reqOther.snackingGuidance(this.$store.getters.axios)
      if (res.data?.data?.isActive) {
        this.data = res?.data?.data
      }
    },
    onClickCta() {
      this.$posthog.capture('pedomanjajanclient')
      window.open(this.data?.link, '_blank')
    },
  },
}
</script>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "h": "150px"
    }
  }, [!_vm.image ? _c('c-box', {
    attrs: {
      "w": "100%",
      "h": "100%"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1) : _c('c-image', {
    attrs: {
      "src": _vm.image ? _vm.image : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039',
      "alt": "dietela",
      "w": "100%",
      "object-fit": "cover",
      "h": "100%"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
export const GENDERS = [
  {
    name: 'male',
    label: 'Laki-laki',
  },
  {
    name: 'female',
    label: 'Perempuan',
  },
]

export const PROGRAM_DURATION = [
  {
    label: 'Awalan',
    value: 30,   
  },
  {
    label: 'Ideal',
    value: 90, 
  },
  {
    label: 'Advanced',
    value: 180,
  },
]

export const DAYS = [
  {
    value: 'Senin',
    label: 'Senin',
    order: 1,
  },
  {
    value: 'Selasa',
    label: 'Selasa',
    order: 2,
  },
  {
    value: 'Rabu',
    label: 'Rabu',
    order: 3,
  },
  {
    value: 'Kamis',
    label: 'Kamis',
    order: 4,
  },
  {
    value: 'Jumat',
    label: 'Jumat',
    order: 5,
  },
  
  {
    value: 'Sabtu',
    label: 'Sabtu',
    order: 6,
  },
  {
    value: 'Minggu',
    label: 'Minggu',
    order: 7,
  },
]

export const SPECIALIZATION = [
  {
    value: 'Manajemen Berat Badan',
    label: 'Manajemen Berat Badan',
    order: 1,
  },
  {
    value: 'Diet Penyakit Metabolik (DM, Hipertensi, Kolesterol)',
    label: 'Diet Penyakit Metabolik (DM, Hipertensi, Kolesterol)',
    order: 2,
  },
  {
    value: 'Diet Masalah Kewanitaan (Kesuburan, Gangguan Haid, PCOS)',
    label: 'Diet Masalah Kewanitaan (Kesuburan, Gangguan Haid, PCOS)',
    order: 3,
  },
  {
    value: 'Diet Masalah Pencernaan (Maag/GERD, gangguan usus & lambung)',
    label: 'Diet Masalah Pencernaan (Maag/GERD, gangguan usus & lambung)',
    order: 4,
  },
  {
    value: 'Gizi Ibu Hamil & Menyusui',
    label: 'Gizi Ibu Hamil & Menyusui',
    order: 5,
  },
  {
    value: 'Diet Anak Remaja (12 tahun keatas)',
    label: 'Diet Anak Remaja (12 tahun keatas)',
    order: 6,
  },
  {
    value: 'Menangani klien di akhir pekan',
    label: 'Menangani klien di akhir pekan',
    order: 7,
  },
  {
    value: 'Diet untuk Penyakit Komplikasi ',
    label: 'Diet untuk Penyakit Komplikasi ',
    order: 8,
  },
  {
    value: 'Menangani Klien Luar Negeri',
    label: 'Menangani Klien Luar Negeri',
    order: 9,
  },
  {
    value: 'Lainnya',
    label: 'Lainnya',
    order: 10,
    isOther: true,
  },
]

export const LINK_DIETELA_SHOP = 'https://dietelablog.my.canva.site/website-page-dietela-shop'
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": ['100%', '80%'],
      "max-width": "1100px",
      "mx": "auto",
      "justify": "center",
      "align": "center",
      "pb": ['8px', '1rem']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "px": ['16px', '0px'],
      "mb": ['5px', '0px'],
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '28px'],
      "font-weight": ['500', '700']
    }
  }, [_vm._v(" Promo & Event Menarik ")]), false ? _c('c-link', {
    attrs: {
      "color": "#008C81",
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "as": "a",
      "href": "#",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Lihat Semua ")]) : _vm._e()], 1), _c('c-box', [_vm.promoHighlight.length > 0 ? _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '10px 10px',
          '@media(max-width: 767px)': {
            padding: '0 0 0 10px'
          }
        }
      },
      expression: "{\n        '.slick-slide': {\n          padding: '10px 10px',\n          '@media(max-width: 767px)': {\n            padding: '0 0 0 10px',\n          }\n        }\n      }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.promoHighlight, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "bg-color": "white",
        "border-radius": "12px",
        "overflow": "hidden",
        "as": "a",
        "box-shadow": ['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)'],
        "max-w": ['300px', '100%']
      },
      on: {
        "click": function click($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": item.image ? item.image : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039',
        "alt": "dietela",
        "w": "100%"
      }
    })], 1);
  }), 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
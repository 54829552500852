var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "max-width": "1100px",
      "d": "flex",
      "justify-content": "center",
      "flex-direction": "column",
      "align-items": "center",
      "py": ['16px', '8px'],
      "px": ['16px', '8px'],
      "mx": "auto",
      "mb": "16px",
      "background-color": "#FFF",
      "border-radius": ['0', '16px']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "center",
      "align-items": "flex-start",
      "mb": ['16px', '32px'],
      "px": ['0px', '0px'],
      "pb": ['6px', '0px'],
      "w": "100%",
      "border-bottom": ['1px solid #C4C4C4', 'none']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '28px'],
      "font-weight": "bold",
      "color": "#3A3A3A",
      "font-family": "Roboto",
      "mr": "6px"
    }
  }, [_vm._v(" Dietela, Solusi Untuk Masalah Dietmu ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/ic-lightbulb.svg'),
      "height": ['25px', '30px'],
      "width": ['25px', '30px']
    }
  })], 1), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(4, 1fr)', 'repeat(4, 1fr)'],
      "gap": ['0', '56px'],
      "mx": "auto"
    }
  }, _vm._l(_vm.advantages, function (item, index) {
    return _c('c-flex', {
      key: index,
      attrs: {
        "w": "100%",
        "flex-direction": ['row', 'column'],
        "align-items": ['flex-start', 'center']
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "justify-content": ['space-between', 'center'],
        "w": "100%",
        "h": "100%",
        "border-right": [_vm.advantages.length == index + 1 ? 'none' : '0px solid #C4C4C4', 'none'],
        "flex-shrink": "0"
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-direction": "row",
        "justify-content": ['flex-start', 'center'],
        "align-items": ['flex-start', 'center'],
        "w": "100%"
      }
    }, [_c('c-text', {
      attrs: {
        "w": ['100%', 'fit-content'],
        "color": "#000000",
        "font-size": ['12px', '16px'],
        "font-weight": "500",
        "font-family": "Roboto",
        "mr": ['0px', '8px'],
        "text-align": "center",
        "order": ['2', '1']
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('c-image', {
      attrs: {
        "display": ['none', 'block'],
        "src": require('@/assets/tabler_circle-check.svg'),
        "width": "20px",
        "height": "20px",
        "flex-shrink": "0",
        "order": ['1', '2'],
        "mr": ['8px', '0px']
      }
    })], 1), _c('c-flex', {
      attrs: {
        "height": ['90px', '150px'],
        "justify-content": "center",
        "align-items": ['flex-end', 'center']
      }
    }, [_c('c-image', {
      attrs: {
        "src": item.image,
        "width": [item.widthMd, item.width],
        "height": [item.heightMd, 'auto']
      }
    })], 1)], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
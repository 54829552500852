var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
      "pt": ['0px', '12px'],
      "pb": ['8px', '38px']
    }
  }, [_c('c-box', {
    attrs: {
      "d": ['none', 'block'],
      "border-radius": ['0px', '8px'],
      "box-shadow": ['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": 155,
      "height": 324,
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-box', {
    attrs: {
      "d": "flex",
      "w": "100%",
      "mx": ['0px', '12px'],
      "border-radius": ['0px', '8px'],
      "box-shadow": ['none', '0px 4px 8px rgba(0, 0, 0, 0.2)'],
      "height": ['200px', '396px'],
      "aspect-ratio": "16/9"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "d": ['none', 'block'],
      "border-radius": ['0px', '8px'],
      "box-shadow": ['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": 155,
      "height": 324,
      "rounded": "",
      "animation": "fade"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
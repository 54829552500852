// ref: https://gitlab.com/web-apps-dietela/backend/-/merge_requests/319

const target = async(axios, payload) => {
  return axios.get(`/v1/general/program-page/target/${payload?.slug}`, {
    params: {
      duration: payload?.params?.duration, // number! = 30 | 90
      group: payload?.params?.group, // string! = comparation | target
    },
  })
}

export const reqProgramPage = {
  target,
}
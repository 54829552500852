var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "max-w": "1100px",
      "mx": "auto",
      "w": "100%",
      "pb": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "mx": ['20px', '0']
    }
  }, [_c('c-text', {
    attrs: {
      "align": "center",
      "font-weight": ['500', '700'],
      "font-size": ['16px', '28px'],
      "color": "black",
      "font-family": "Roboto"
    }
  }, [_vm._v(" FAQ ")]), _vm.faq.length > 0 ? _c('c-accordion', {
    attrs: {
      "default-index": [],
      "allow-multiple": false,
      "allow-toggle": true,
      "d": "grid",
      "grid-template-columns": ['1fr', '1fr 1fr'],
      "mx": "auto",
      "gap": "8px",
      "px": ['0px', '30px'],
      "py": ['12px', '18px']
    }
  }, _vm._l(_vm.faq, function (item, index) {
    return _c('c-accordion-item', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: 'white'
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none !important',
            background: 'white !important'
          }
        },
        expression: "{\n          ':hover': {\n            backgroundColor: 'white',\n          },\n          ':focus': {\n            outline: 'none',\n            boxShadow: 'none !important',\n            background: 'white !important',\n          },\n        }"
      }],
      key: index,
      attrs: {
        "w": "100%",
        "bg": "white",
        "border-radius": "8px",
        "h": "fit-content",
        "font-family": "Roboto"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var isExpanded = _ref.isExpanded;
          return [_c('c-accordion-header', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                ':hover': {
                  backgroundColor: 'white',
                  borderRadius: '8px'
                },
                ':focus': {
                  outline: 'none',
                  boxShadow: 'none !important',
                  background: 'white !important',
                  borderRadius: '8px'
                }
              },
              expression: "{\n            ':hover': {\n              backgroundColor: 'white',\n              borderRadius: '8px',\n            },\n            ':focus': {\n              outline: 'none',\n              boxShadow: 'none !important',\n              background: 'white !important',\n              borderRadius: '8px',\n            },\n          }"
            }],
            attrs: {
              "h": ['auto', '64px']
            }
          }, [_c('c-box', {
            attrs: {
              "flex": "1",
              "text-align": "left",
              "font-size": ['14px', '16px'],
              "font-weight": ['400'],
              "color": "#000000",
              "pr": "20px"
            }
          }, [_vm._v(" " + _vm._s(index + 1 + '. ' + item.question) + " ")]), _c('c-image', {
            attrs: {
              "src": require('@/assets/icon-accordion.svg'),
              "h": ['10px'],
              "w": ['5px'],
              "transform": !isExpanded ? 'rotate(0)' : 'rotate(-90deg)',
              "animation": "1s transform ease"
            }
          })], 1), _c('c-accordion-panel', {
            attrs: {
              "pb": "4",
              "font-size": ['14px', '16px'],
              "font-weight": ['400'],
              "color": "#000000",
              "text-align": "justify",
              "font-family": "Roboto"
            }
          }, _vm._l(_vm.splitString(item.answer), function (item_, index_) {
            return _c('c-text', {
              key: index_,
              attrs: {
                "font-family": "Roboto"
              }
            }, [_c('c-text', {
              attrs: {
                "font-family": "Roboto"
              },
              domProps: {
                "innerHTML": _vm._s(item_ === '' ? '<br>' : item_)
              }
            })], 1);
          }), 1)];
        }
      }], null, true)
    });
  }), 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
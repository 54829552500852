var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "position": "relative",
      "max-w": "1100px",
      "mx": "auto",
      "w": "100%",
      "pb": "1rem",
      "z-index": "10"
    }
  }, [_c('c-flex', {
    attrs: {
      "bg": "#F1E456",
      "border-radius": ['4px 4px 4px 40px', '4px 4px 4px 100px'],
      "flex-direction": "rows",
      "py": ['12px', '67px'],
      "pr": ['16px', '140px'],
      "pl": ['0', '130px'],
      "justify-content": "space-between",
      "mx": ['16px', '0']
    }
  }, [_c('c-box', [_c('c-box', {
    attrs: {
      "mt": "-67px",
      "mb": "-200px",
      "ml": "-60px",
      "display": ['none', 'block']
    }
  }, [_c('svg', {
    attrs: {
      "width": "500",
      "height": "250",
      "viewBox": "0 0 500 250",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "250",
      "r": "250",
      "fill": "#F4CC46"
    }
  })])]), _c('c-image', {
    attrs: {
      "src": 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/nama_icon.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676449921670',
      "h": ['100px', '250px'],
      "mt": ['-18px', '0'],
      "mb": ['0px', '-67px']
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align": "end",
      "gap": ['5px', '20px'],
      "ml": ['-50px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '28px'],
      "font-weight": ['700'],
      "color": "#008C81",
      "text-align": "right"
    }
  }, [_vm._v(" Dietela Quiz ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "color": "#008C81",
      "text-align": "right"
    }
  }, [_vm._v(" Temukan Diet Terbaik Untukmu ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "rows",
      "px": ['10px', '85.5px'],
      "py": ['5px', '15px'],
      "align-items": "center",
      "gap": ['10px'],
      "align": "end",
      "bg": "#008C81",
      "border-radius": ['16px', '58px'],
      "w": "fit-content",
      "as": "a",
      "href": _vm.ENV.URL_DIETELA_NAMA,
      "target": "_blank"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": ['500'],
      "font-size": ['14px', '18px'],
      "color": "white"
    }
  }, [_vm._v(" Coba Gratis ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-nama.svg'),
      "h": ['24px', '30px'],
      "w": ['24px', '30px']
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
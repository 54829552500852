var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": ['100%', '80%'],
      "max-width": "1100px",
      "mx": "auto",
      "justify": "center",
      "align": "center",
      "pb": ['12px', '1rem']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "px": ['16px', '0px'],
      "mb": ['5px', '0px'],
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '28px'],
      "font-weight": ['500', '700']
    }
  }, [_vm._v(" Panduan diet ")]), false ? _c('c-link', {
    attrs: {
      "color": "#008C81",
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "font-family": "Roboto",
      "as": "router-link",
      "to": {
        name: 'content-challenge.coaching-course'
      }
    }
  }, [_vm._v(" Lihat Semua ")]) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "px": ['10px', '0px']
    }
  }, [_vm.coachingCourse.length > 0 ? _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '10px 10px',
          '@media(max-width: 767px)': {
            padding: '0 5px'
          }
        }
      },
      expression: "{\n        '.slick-slide': {\n          padding: '10px 10px',\n          '@media(max-width: 767px)': {\n            padding: '0 5px',\n          }\n        }\n      }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.duplicateCoachingCourse, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "bg-color": "white",
        "border-radius": "12px",
        "h": ['150px', '250px'],
        "box-shadow": ['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)'],
        "overflow": "hidden",
        "draggable": "true",
        "cursor": "pointer",
        "pointer-events": _vm.disabledEvent ? 'none' : 'auto',
        "user-select": "none"
      },
      on: {
        "dragstart": function dragstart($event) {
          $event.preventDefault();
          return _vm.onDrag.apply(null, arguments);
        },
        "click": function click($event) {
          $event.preventDefault();
          return _vm.openCoachingCourse(item.id);
        }
      }
    }, [_c('c-box', {
      attrs: {
        "h": ['80px', '150px'],
        "mb": ['5px', '10px']
      }
    }, [_c('c-image', {
      attrs: {
        "src": item !== null && item !== void 0 && item.coverImage ? item === null || item === void 0 ? void 0 : item.coverImage : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039',
        "alt": "dietela",
        "max-h": ['80px', '150px'],
        "w": "100%",
        "object-fit": "cover",
        "user-select": "none"
      }
    })], 1), _c('c-box', {
      attrs: {
        "h": ['70px', '80px'],
        "overflow": "hidden",
        "px": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-weight": ['400', '500'],
        "font-size": ['14px', '18px'],
        "color": "#111111",
        "font-family": "Roboto",
        "text-align": "left",
        "mb": "5px"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)], 1);
  }), 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
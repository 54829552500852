// ref: https://gitlab.com/web-apps-dietela/backend/-/merge_requests/317
const whyDietela = async(axios) => {
  return axios.get('/v1/general/why-dietela')
}

// ref: https://gitlab.com/web-apps-dietela/backend/-/merge_requests/318
const programWithExpert = async(axios) => {
  return axios.get('/v1/general/program-with-expert')
}

// ref: https://gitlab.com/web-apps-dietela/backend/-/merge_requests/653
const snackingGuidance = async(axios) => {
  return axios.get('/v1/clients/snacking-guidance')
}
export const reqOther = {
  whyDietela,
  programWithExpert,
  snackingGuidance,
}
var render = function () {
  var _vm$data, _vm$data$imageDescrip, _vm$data$imageDescrip2, _vm$data2, _vm$data2$imageDescri, _vm$data2$imageDescri2, _vm$data3, _vm$data3$imageDescri, _vm$data3$imageDescri2, _vm$data4, _vm$data4$imageDescri, _vm$data4$imageDescri2, _vm$data5, _vm$data5$imageDescri, _vm$data5$imageDescri2, _vm$data6, _vm$data6$imageDescri, _vm$data6$imageDescri2, _vm$data7, _vm$data7$imageDescri, _vm$data7$imageDescri2, _vm$data8, _vm$data8$imageDescri, _vm$data8$imageDescri2, _vm$data9;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "z-index": "10",
      "max-width": "1100px",
      "mx": "auto",
      "height": ['100px', '260px']
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "gap": "8px",
      "height": "100%",
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "left": "10",
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "position": "relative",
      "right": ['-10px', 'unset'],
      "left": ['unset', '8px'],
      "top": "-15px"
    }
  }, [!!((_vm$data = _vm.data) !== null && _vm$data !== void 0 && (_vm$data$imageDescrip = _vm$data.imageDescription) !== null && _vm$data$imageDescrip !== void 0 && (_vm$data$imageDescrip2 = _vm$data$imageDescrip[0]) !== null && _vm$data$imageDescrip2 !== void 0 && _vm$data$imageDescrip2.image) ? _c('c-image', {
    attrs: {
      "max-width": ['100px', '220px'],
      "max-height": ['100px', '220px'],
      "src": (_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$imageDescri = _vm$data2.imageDescription) === null || _vm$data2$imageDescri === void 0 ? void 0 : (_vm$data2$imageDescri2 = _vm$data2$imageDescri[0]) === null || _vm$data2$imageDescri2 === void 0 ? void 0 : _vm$data2$imageDescri2.image
    }
  }) : _vm._e(), _c('c-box', {
    attrs: {
      "margin-top": "-10px",
      "background": ['linear-gradient(270deg, #FFF -0.08%, rgba(255, 255, 255, 0.40) 85.15%)', 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.40) 91.04%)'],
      "min-width": ['0', '250px'],
      "padding": ['6px 12px', '12px 6px'],
      "border-radius": "8px",
      "color": "primary.400",
      "text-align": "center",
      "backdrop-filter": "blur(2px)",
      "position": "absolute",
      "bottom": "-50%",
      "transform": ['translateY(-80%)', 'translateY(-145%)']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$imageDescri = _vm$data3.imageDescription) === null || _vm$data3$imageDescri === void 0 ? void 0 : (_vm$data3$imageDescri2 = _vm$data3$imageDescri[0]) === null || _vm$data3$imageDescri2 === void 0 ? void 0 : _vm$data3$imageDescri2.name) || '') + " ")]), _c('c-text', {
    attrs: {
      "font-size": ['10px', '14px'],
      "font-weight": ['500'],
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : (_vm$data4$imageDescri = _vm$data4.imageDescription) === null || _vm$data4$imageDescri === void 0 ? void 0 : (_vm$data4$imageDescri2 = _vm$data4$imageDescri[0]) === null || _vm$data4$imageDescri2 === void 0 ? void 0 : _vm$data4$imageDescri2.title) || '') + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "position": "relative",
      "right": ['-10px', 'unset'],
      "left": ['unset', '-20px'],
      "top": ['10px', '10px']
    }
  }, [!!((_vm$data5 = _vm.data) !== null && _vm$data5 !== void 0 && (_vm$data5$imageDescri = _vm$data5.imageDescription) !== null && _vm$data5$imageDescri !== void 0 && (_vm$data5$imageDescri2 = _vm$data5$imageDescri[1]) !== null && _vm$data5$imageDescri2 !== void 0 && _vm$data5$imageDescri2.image) ? _c('c-image', {
    attrs: {
      "max-width": ['100px', '220px'],
      "max-height": ['100px', '220px'],
      "src": (_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$imageDescri = _vm$data6.imageDescription) === null || _vm$data6$imageDescri === void 0 ? void 0 : (_vm$data6$imageDescri2 = _vm$data6$imageDescri[1]) === null || _vm$data6$imageDescri2 === void 0 ? void 0 : _vm$data6$imageDescri2.image
    }
  }) : _vm._e(), _c('c-box', {
    attrs: {
      "margin-top": "-10px",
      "background": ['linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.40) 86.98%)', 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.40) 91.04%)'],
      "min-width": ['0', '250px'],
      "padding": ['6px 12px', '12px 6px'],
      "border-radius": "8px",
      "color": "primary.400",
      "text-align": "center",
      "backdrop-filter": "blur(2px)",
      "position": "absolute",
      "bottom": "-50%",
      "transform": ['translateY(-50%)', 'translateY(-90%)']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : (_vm$data7$imageDescri = _vm$data7.imageDescription) === null || _vm$data7$imageDescri === void 0 ? void 0 : (_vm$data7$imageDescri2 = _vm$data7$imageDescri[1]) === null || _vm$data7$imageDescri2 === void 0 ? void 0 : _vm$data7$imageDescri2.name) || '') + " ")]), _c('c-text', {
    attrs: {
      "font-size": ['10px', '14px'],
      "font-weight": ['500'],
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(((_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : (_vm$data8$imageDescri = _vm$data8.imageDescription) === null || _vm$data8$imageDescri === void 0 ? void 0 : (_vm$data8$imageDescri2 = _vm$data8$imageDescri[1]) === null || _vm$data8$imageDescri2 === void 0 ? void 0 : _vm$data8$imageDescri2.title) || '') + " ")])], 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "flex-start"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "36px",
      "color": "white",
      "text-align": "center",
      "line-height": "2"
    },
    domProps: {
      "innerHTML": _vm._s(((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : _vm$data9.header) || '')
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
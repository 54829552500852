var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "max-w": "1100px",
      "mx": "auto",
      "w": "100%",
      "pb": "1rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "bg": "#F1E456",
      "border-radius": ['4px 4px 40px 4px', '4px 4px 100px 40px'],
      "flex-direction": "rows",
      "py": ['12px', '67px'],
      "pr": ['16px', '50px'],
      "pl": ['0', '130px'],
      "justify-content": "space-between",
      "mx": ['16px', '0'],
      "isolation": "isolate",
      "overflow": ['unset', 'hidden']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align": "start",
      "gap": ['5px', '20px'],
      "ml": ['20px', '-50px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '28px'],
      "font-weight": ['700'],
      "color": "#008C81",
      "text-align": "right"
    }
  }, [_vm._v(" Konsultasi Sekarang ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "rows",
      "px": ['10px', '85.5px'],
      "py": ['5px', '15px'],
      "align-items": "center",
      "gap": ['10px'],
      "align": "end",
      "bg": "#008C81",
      "border-radius": ['16px', '58px'],
      "w": "fit-content",
      "as": "a",
      "href": 'https://wa.me/6281213734726',
      "target": "_blank"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": ['500'],
      "font-size": ['14px', '18px'],
      "color": "white"
    }
  }, [_vm._v(" Chat Dietela ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-konsultasi.svg'),
      "h": ['24px', '30px'],
      "w": ['24px', '30px']
    }
  })], 1)], 1), _c('c-box', [_c('c-box', {
    attrs: {
      "mt": "0px",
      "mb": "-250px",
      "ml": "-120px",
      "display": ['none', 'block']
    }
  }, [_c('svg', {
    attrs: {
      "width": "500",
      "height": "251",
      "viewBox": "0 0 500 251",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "250",
      "cy": "250",
      "r": "250",
      "fill": "#F4CC46"
    }
  })])]), _c('c-image', {
    attrs: {
      "src": 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/nutritionist_3.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676455017704',
      "h": ['100px', '250px'],
      "mt": ['-18px', '0'],
      "mb": ['-12px', '-67px']
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm$data;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": ['100%', '75%'],
      "max-width": "800px",
      "mx": "auto",
      "gap": "6px"
    }
  }, [_c('c-flex', {
    attrs: {
      "position": "relative",
      "w": "100%",
      "min-height": ['155px', '255px'],
      "flex-direction": ['row', _vm.align == 'left' ? 'row-reverse' : 'row'],
      "justify-content": ['flex-start', 'center'],
      "align-items": ['normal', 'flex-end'],
      "background-color": "#00A68C",
      "box-shadow": ['0px 2px 4px rgba(0, 0, 0, 0.1)', '0px 4px 12px rgba(0, 0, 0, 0.2)'],
      "border-radius": "12px",
      "p": ['16px 10px 10px 10px', '20px 30px'],
      "gap": ['0px', '10px']
    }
  }, [_c('c-image', {
    attrs: {
      "src": (_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.photoLandingPage,
      "pos": "absolute",
      "display": ['block', 'none'],
      "top": "calc(-100% + 97px)",
      "right": _vm.align == 'left' ? '16px' : 'unset',
      "left": _vm.align == 'left' ? 'unset' : '16px',
      "height": "98px",
      "border-radius": "10px",
      "transform": _vm.align == 'left' ? 'scaleX(-1)' : '',
      "z-index": "2"
    }
  }), _c('c-flex', {
    attrs: {
      "display": ['none', 'flex'],
      "pos": "relative",
      "flex-grow": "1",
      "height": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.data.photoLandingPage,
      "pos": "absolute",
      "bottom": "0",
      "w": ['100px', '250px'],
      "border-radius": "10px",
      "margin-left": "24px",
      "transform": _vm.align == 'left' ? 'scaleX(-1)' : ''
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "space-between",
      "align-items": "center",
      "width": ['100%', '440px']
    }
  }, [_c('c-text', {
    attrs: {
      "width": "100%",
      "color": "#FFF",
      "font-size": ['16px', '28px'],
      "mb": ['8px', '16px'],
      "font-weight": "500",
      "font-family": "Roboto",
      "text-align": [_vm.align, 'center']
    }
  }, [_vm._v(" " + _vm._s(_vm.data.name) + " ")]), _c('c-text', {
    attrs: {
      "width": "100%",
      "color": "#FFF",
      "font-size": ['12px', '18px'],
      "text-align": ['left', 'justify'],
      "px": ['0px', '16px'],
      "mb": ['8px', '16px'],
      "font-weight": ['400', '500'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.overview) + " ")]), _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: '#CFC13E',
          color: '#005A64'
        }
      },
      expression: "{\n          ':hover': {\n            backgroundColor: '#CFC13E',\n            color: '#005A64',\n          },\n        }"
    }],
    attrs: {
      "d": "flex",
      "flex-direction": "row",
      "justify-content": "center",
      "align-items": "center",
      "width": ['auto', '290px'],
      "border-radius": ['24px', '60px'],
      "py": ['8px', '12px'],
      "height": ['32px', '60px'],
      "background": "secondary.400",
      "color": "primary.400",
      "is-loading": _vm.loading,
      "loading-text": "Memuat Data",
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "gap": "16px"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('on-click', _vm.data);
      }
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Lihat Layanan ")]), _c('c-box', {
    attrs: {
      "width": ['16px', '20px'],
      "height": ['16px', '20px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-arrow-right.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <c-box
    pos="relative"
    z-index="10"
    max-width="1100px"
    mx="auto"
    :height="['100px', '260px']"
  >
    <c-flex
      justify-content="center"
      align-items="center"
      gap="8px"
      height="100%"
      width="100%"
    >
      <c-box width="100%">
        <c-box
          width="100%"
          left="10"
          display="flex"
          justify-content="flex-end"
        >
          <c-flex
            flex-direction="column"
            align-items="center"
            position="relative"
            :right="['-10px', 'unset']"
            :left="['unset', '8px']"
            top="-15px"
          >
            <c-image
              v-if="!!data?.imageDescription?.[0]?.image"
              :max-width="['100px', '220px']"
              :max-height="['100px', '220px']"
              :src="data?.imageDescription?.[0]?.image"
            />
            <c-box
              margin-top="-10px"
              :background="['linear-gradient(270deg, #FFF -0.08%, rgba(255, 255, 255, 0.40) 85.15%)', 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.40) 91.04%)']"
              :min-width="['0','250px']"
              :padding="['6px 12px', '12px 6px']"
              border-radius="8px"
              color="primary.400"
              text-align="center"
              backdrop-filter="blur(2px)"
              position="absolute"
              bottom="-50%"
              :transform="['translateY(-80%)', 'translateY(-145%)']"
            >
              <c-text
                :font-size="['12px', '18px']"
                :font-weight="['400', '500']"
                white-space="nowrap"
              >
                {{ data?.imageDescription?.[0]?.name || '' }}
              </c-text>
              <c-text
                :font-size="['10px', '14px']"
                :font-weight="['500']"
                white-space="nowrap"
              >
                {{ data?.imageDescription?.[0]?.title || '' }}
              </c-text>
            </c-box>
          </c-flex>
          <c-flex
            flex-direction="column"
            align-items="center"
            position="relative"
            :right="['-10px', 'unset']"
            :left="['unset', '-20px']"
            :top="['10px', '10px']"
          >
            <c-image
              v-if="!!data?.imageDescription?.[1]?.image"
              :max-width="['100px', '220px']"
              :max-height="['100px', '220px']"
              :src="data?.imageDescription?.[1]?.image"
            />
            <c-box
              margin-top="-10px"
              :background="['linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.40) 86.98%)', 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.40) 91.04%)']"
              :min-width="['0','250px']"
              :padding="['6px 12px', '12px 6px']"
              border-radius="8px"
              color="primary.400"
              text-align="center"
              backdrop-filter="blur(2px)"
              position="absolute"
              bottom="-50%"
              :transform="['translateY(-50%)', 'translateY(-90%)']"
            >
              <c-text
                :font-size="['12px', '18px']"
                :font-weight="['400', '500']"
                white-space="nowrap"
              >
                {{ data?.imageDescription?.[1]?.name || '' }}
              </c-text>
              <c-text
                :font-size="['10px', '14px']"
                :font-weight="['500']"
                white-space="nowrap"
              >
                {{ data?.imageDescription?.[1]?.title || '' }}
              </c-text>
            </c-box>
          </c-flex>
        </c-box>
      </c-box>
      <c-box width="100%">
        <c-flex
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
        >
          <BaseText
            size-mobile="14px"
            size-desktop="36px"
            color="white"
            text-align="center"
            line-height="2"
            v-html="data?.header || ''"
          />
        </c-flex>
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
import { CBox, CFlex } from '@chakra-ui/vue'
import BaseText from '@/components/elements/base-text.vue'
import { reqOther } from '@/requests/dietela-api/general/other'

export default {
  name: 'StartProgram',
  components: {
    BaseText,
    CBox,
    CFlex,
  },
  data() {
    return {
      data: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const res = await reqOther.programWithExpert(this.$store.getters.axios)
      this.data = res?.data?.data
    },
  },
}
</script>

<style scoped>
::v-deep .highlight {
  font-size: 16px;
}

@media (min-width: 768px) {
  ::v-deep .highlight {
    font-size: 40px;
  }
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "id": "landingpage",
      "background-color": ['#F2F2F2', _vm.isAuthenticated ? '#FFFFFF' : '#F2F2F2'],
      "py": ['0px', _vm.isAuthenticated ? '1rem' : '0px'],
      "border-radius": _vm.isAuthenticated ? ['0px', '16px'] : '0px',
      "box-shadow": _vm.isAuthenticated ? ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'] : ''
    }
  }, [_c('Banner'), !_vm.isAuthenticated ? _c('MiniDashboard') : _vm._e(), _c('c-box', {
    attrs: {
      "position": "relative",
      "background-image": ['none', require('@/assets/bg-vector.png')],
      "background-size": "cover",
      "background-position": "right bottom",
      "background-repeat": "no-repeat",
      "z-index": "2"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "absolute",
      "max-width": "1200px",
      "mx": "auto",
      "width": "100%",
      "height": ['600px', '860px'],
      "background-image": _vm.isAuthenticated ? 'unset' : [require('@/assets/images/background-program-mobile.svg'), require('@/assets/images/background-program.svg')],
      "background-size": "100%",
      "background-position": ['center 20px', 'center 150px'],
      "background-repeat": "no-repeat",
      "transform": "translateX(-50%)",
      "left": "50%",
      "z-index": "2"
    }
  }), _c('c-box', {
    attrs: {
      "pos": "relative",
      "max-width": "1200px",
      "mx": "auto",
      "background": _vm.isAuthenticated ? 'unset' : 'linear-gradient(180deg, rgba(0, 140, 130, 0.9) 0%, rgba(255, 255, 255, 0.18) 82.96%)',
      "rounded-top-left": ['25px', '150px']
    }
  }, [!_vm.isAuthenticated ? _c('StartProgram') : _vm._e(), _vm.isAuthenticated ? _c('c-box', [_c('c-box', {
    attrs: {
      "width": "100%",
      "height": ['170px'],
      "position": "relative",
      "background-color": "#00A68C",
      "border-bottom-left-radius": ['12px', '0px'],
      "border-bottom-right-radius": ['12px', '0px'],
      "overflow": "hidden"
    }
  }, [_c('c-image', {
    attrs: {
      "display": ['block', 'none'],
      "src": require('@/assets/images/decoration-program-active.png'),
      "position": "absolute",
      "height": "100%",
      "width": "auto",
      "left": "0"
    }
  }), _c('c-image', {
    attrs: {
      "display": ['block', 'none'],
      "src": require('@/assets/images/decoration-program-active.png'),
      "position": "absolute",
      "height": "100%",
      "width": "auto",
      "right": "0",
      "transform": "scaleX(-1)"
    }
  }), _c('c-box', {
    attrs: {
      "display": ['none', 'block'],
      "position": "absolute",
      "bottom": "-270px",
      "right": "-100px",
      "width": "450px",
      "height": "450px",
      "border-radius": "450px",
      "background-color": "#008C81"
    }
  })], 1), _c('c-box', {
    attrs: {
      "pt": "1rem",
      "px": "1rem",
      "margin-top": "-140px"
    }
  }, [_c('ActiveProgram')], 1), _c('SncakingGuidance')], 1) : _vm._e(), _vm.isAuthenticated && _vm.userRole === 'client' ? _c('MainMenu') : _vm._e(), _c('Program')], 1), _c('portal-target', {
    attrs: {
      "name": "program-recommendation",
      "transition": _vm.fadeTransition
    }
  }), _vm.isAuthenticated && _vm.userRole === 'client' ? _c('DietelaQuiz') : _vm._e(), !_vm.isAuthenticated ? _c('Testimonial') : _vm._e(), _c('PromoHighlight')], 1), _c('c-box', {
    attrs: {
      "background-image": [_vm.isAuthenticated ? 'unset' : require('@/assets/images/background-mobile.svg'), _vm.isAuthenticated ? 'unset' : require('@/assets/images/background.svg')],
      "background-size": "contain",
      "background-position": ['center 89%', 'center 17%'],
      "background-repeat": "no-repeat",
      "z-index": "10"
    }
  }, [!_vm.isAuthenticated ? _c('Advantages') : _vm._e(), _c('CoachingCourse'), _vm.isAuthenticated && _vm.userRole === 'client' ? _c('KonsultasiAdmin') : _vm._e(), !_vm.isAuthenticated ? _c('PilihDietela') : _vm._e(), !_vm.isAuthenticated ? _c('ServiceStage') : _vm._e()], 1), _c('Artikel'), !_vm.isAuthenticated ? _c('FAQ') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "z-index": "10"
    }
  }, [_c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "max-width": "1100px",
      "d": "flex",
      "flex-direction": "column",
      "pb": ['36px', '32px'],
      "px": "0",
      "mx": "auto"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '28px'],
      "color": "#111111",
      "px": ['16px', '0px'],
      "mb": ['5px', '10px'],
      "font-weight": [500, 700]
    }
  }, [_vm._v(" Apa Kata Mereka? ")]), _vm.testimonials.length > 0 ? _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '0 0 0 16px'
        },
        '.slick-dots': {
          bottom: '-25px',
          li: {
            '@media(max-width: 767px)': {
              margin: '0 3px'
            },
            button: {
              '::before': {
                fontSize: '8px',
                color: '#D9D9D9',
                opacity: '1',
                '@media(max-width: 767px)': {
                  fontSize: '10px'
                }
              }
            },
            '&.slick-active': {
              button: {
                '::before': {
                  fontSize: '12px',
                  color: '#008C81',
                  opacity: '1'
                }
              }
            }
          }
        }
      },
      expression: "{\n        '.slick-slide': {\n          padding: '0 0 0 16px',\n        },\n        '.slick-dots': {\n          bottom: '-25px',\n          li: {\n            '@media(max-width: 767px)': {\n              margin: '0 3px',\n            },\n            button: {\n              '::before': {\n                fontSize: '8px',\n                color: '#D9D9D9',\n                opacity: '1',\n                '@media(max-width: 767px)': {\n                  fontSize: '10px',\n                },\n              },\n            },\n            '&.slick-active': {\n              button: {\n                '::before': {\n                  fontSize: '12px',\n                  color: '#008C81',\n                  opacity: '1',\n                },\n              },\n            },\n          },\n        },\n      }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.testimonials, function (item, index) {
    return _c('c-box', {
      key: index
    }, [_c('c-image', {
      attrs: {
        "src": item.image,
        "display": ['none', 'block'],
        "width": ['120px', '130px'],
        "height": ['120px', '130px'],
        "border-radius": ['60px', '65px'],
        "border": "4px solid #008C81",
        "background-color": "#008C81",
        "object-fit": "cover",
        "object-position": "50% 20%",
        "mx": "auto"
      }
    }), _c('c-box', {
      attrs: {
        "w": ['300px', '320px'],
        "mt": ['0', '-65px'],
        "background-color": "#008C81",
        "border-radius": "12px",
        "px": "8px",
        "pt": ['8px', '70px'],
        "pb": ['42px', '8px'],
        "h": ['auto', '320px']
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-direction": "row",
        "align-items": "center",
        "margin-bottom": ['8px', '0']
      }
    }, [_c('c-image', {
      attrs: {
        "src": item.image,
        "display": ['block', 'none'],
        "width": "60px",
        "height": "60px",
        "border-radius": "60px",
        "object-fit": "cover",
        "object-position": "50% 20%",
        "margin-right": "12px"
      }
    }), _c('c-flex', {
      attrs: {
        "flex-grow": "1",
        "display": ['flex', 'block'],
        "flex-direction": "column",
        "font-family": "Roboto",
        "font-size": ['14px', '16px'],
        "color": "#FFFFFF",
        "mb": "8px",
        "text-align": ['left', 'center'],
        "font-weight": [400, 600]
      }
    }, [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.age) + ") "), _c('c-box', {
      attrs: {
        "as": "span",
        "font-size": ['12px', '16px']
      }
    }, [_vm._v(" " + _vm._s(item.location) + " ")])], 1)], 1), _c('c-box', {
      attrs: {
        "font-family": "Roboto",
        "font-size": ['12px', '14px'],
        "color": "#FFFFFF",
        "text-align": ['left', 'center']
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1), _c('c-box', {
      attrs: {
        "w": ['80%', '85%'],
        "mx": "auto",
        "p": "4px",
        "border": "2px solid #008C81",
        "border-radius": "12px",
        "mt": "-35px",
        "background": "#FFFFFF",
        "align": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "#000000",
        "font-size": ['12px', '14px'],
        "font-family": "Roboto",
        "mb": "6px"
      }
    }, [_vm._v(" Program yang digunakan: " + _vm._s(item.product.name) + " ")]), _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: '#005A64'
          }
        },
        expression: "{\n              ':hover': {\n                backgroundColor: '#005A64',\n              },\n            }"
      }],
      attrs: {
        "d": "flex",
        "flex-direction": "row",
        "justify-content": "center",
        "align-items": "center",
        "border-radius": ['24px', '20px'],
        "px": ['16px', '12px'],
        "py": ['4px', '10px'],
        "height": ['32px', 'auto'],
        "background-color": "#008C81",
        "color": "#FFFFFF",
        "is-loading": _vm.isLoading && index == _vm.selectedIndex,
        "loading-text": "Memuat Data",
        "font-family": "Roboto",
        "font-size": ['14px', '14px'],
        "font-weight": "500"
      },
      on: {
        "click": function click($event) {
          return _vm.handleDetailProgram(item.product);
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '14px'],
        "mr": "8px",
        "font-weight": "500",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Lihat Layanan ")]), _c('c-image', {
      attrs: {
        "src": require('@/assets/ic-arrow-right-white-alt.svg'),
        "width": "16px",
        "height": "16px"
      }
    })], 1)], 1)], 1);
  }), 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
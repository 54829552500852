<template>
  <c-box
    v-if="!!data"
    position="relative"
    height="100%"
  > 
    <c-box
      max-w="1100px"
      mx="auto"
      :w="['100%', '80%']"
      pb="16px"
      position="relative"
    >
      <c-box
        :mx="['16px','0']"
      >
        <c-grid
          :template-columns="['1fr', '1fr 2fr']"
          mx="auto"
          gap="16px"
          :px="['20px', '30px']"
          :pt="['30px']"
          :pb="['10px']"
        >
          <!-- image -->
          <c-flex
            flex-direction="column"
            :justify-content="['space-between', 'center']"
            gap="16px"
            w="100%"
            position="relative"
          >
            <BaseText
              :display="['none', 'block']"
              size-mobile="20px"
              size-desktop="28px"
              text-align="center"
              color="primary.400"
              v-html="data?.header"
            />
            
            <c-flex
              flex-direction="column"
              :align="['flex-end', 'center']"
              :mt="['-30px', '0']"
              position="relative"
            >
              <BaseText
                :display="['block', 'none']"
                size-mobile="20px"
                size-desktop="28px"
                text-align="center"
                color="primary.400"
                width="220px"
                top="19px"
                right="100px"
                position="absolute"
                v-html="data?.header"
              />
              <c-image
                v-if="!!data?.image"
                :src="data?.image"
                :max-w="['126px', '230px']"
                align="center !important"
                :margin-right="['20px', '0']"
              />
              <c-box
                :p="['8px 10px']"
                :gap="['10px']"
                border-radius="8px"
                align="center"
                bg="#008C81"
                :mt="['-30px', '-50px']"
              >
                <BaseText
                  size-mobile="14px"
                  size-desktop="20px"
                  text-align="center"
                  color="white"
                  white-space="nowrap"
                >
                  {{ data?.imageDescription?.name || '-' }}
                </BaseText>
                <BaseText
                  size-mobile="12px"
                  size-desktop="16px-3"
                  text-align="center"
                  color="white"
                  white-space="nowrap"
                >
                  {{ data?.imageDescription?.title || '-' }}
                </BaseText>
              </c-box>
            </c-flex>
          </c-flex>

          <!-- text -->
          <c-flex
            flex-direction="column"
            :gap="['16px', '20px']"
          >
            <!-- LIST -->
            <c-flex
              v-for="(item,index) in data?.descriptionItems"
              :key="index"
              flex-direction="rows"
              :gap="['13px', '21px']"
              :border-bottom="['0.5px solid #C4C4C4', '0']"
              background-color="white"
              padding="10px"
              align-items="center"
            >
              <c-image
                :src="require('@/assets/verified.svg')"
                :w="['25px', '30px']"
                :h="['25px', '30px']"
              />
              <c-flex
                flex-direction="column"
                :gap="['0px', '12px']"
              >
                <c-text
                  font-family="Roboto"
                  :font-weight="['600', '500']"
                  :font-size="['12px', '18px']"
                  color="#111111"
                >
                  {{ item.item }}
                </c-text>
                <c-text
                  font-family="Roboto"
                  :font-weight="['400']"
                  :font-size="['12px', '16px']"
                  color="#111111"
                >
                  {{ item.description }}
                </c-text>
              </c-flex>
            </c-flex>
          </c-flex>
        </c-grid>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import { reqOther } from '@/requests/dietela-api/general/other'
import { CBox, CImage, CText, CGrid, CFlex } from '@chakra-ui/vue'

export default {
  name: 'PilihDietela',
  components: {
    BaseText,
    CBox,
    CImage,
    CText,
    CGrid,
    CFlex,
  },
  data() {
    return {
      data: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const res = await reqOther.whyDietela(this.$store.getters.axios)
      this.data = res?.data?.data
    },
  },
}
</script>

<style scoped>
::v-deep .highlight {
  font-size: 36px;
}

@media (max-width: 768px) {
  ::v-deep .highlight {
    font-size: 24px;
  }
}
</style>
<template>
  <c-box
    as="div"
    pos="relative"
    w="100%"
    :background-color="['transparent', '#008C81']"
    :py="['0px', '0px']"
  >
    <c-box
      w="100%"
      max-width="1100px"
      mx="auto"
    >
      <VueSlickCarousel
        v-if="!isLoading && filteredBanners.length > 0"
        v-chakra="{
          '.slick-prev': {
            width: '50px',
            height: '50px',
            left: '150px',
            backgroundImage: require('@/assets/icon_next_left.svg'),
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            zIndex: '1',
            '::before': {
              display: 'none',
            },
            ':focus': {
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              width: '50px',
              height: '50px',
              backgroundImage: require('@/assets/icon_next_left.svg'),
            },
            ':hover': {
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              width: '50px',
              height: '50px',
              backgroundImage: require('@/assets/icon_next_left.svg'),
            },
            '@media(max-width: 767px)': {
              display: 'none !important',
            },
          },
          '.slick-next': {
            right: '150px',
            width: '50px',
            height: '50px',
            backgroundImage: require('@/assets/icon_next_right.svg'),
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            zIndex: '1',
            '::before': {
              display: 'none',
            },
            ':focus': {
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              width: '50px',
              height: '50px',
              backgroundImage: require('@/assets/icon_next_right.svg'),
            },
            ':hover': {
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              width: '50px',
              height: '50px',
              backgroundImage: require('@/assets/icon_next_right.svg'),
            },
            '@media(max-width: 767px)': {
              display: 'none !important',
            },
          },
          '.slick-dots': {
            bottom: '8px',
            '@media(max-width: 767px)': {
              bottom: '15px',
            },
            li: {
              '@media(max-width: 767px)': {
                margin: '0 3px',
              },
              button: {
                '::before': {
                  fontSize: '12px',
                  color: '#FFFFFF',
                  opacity: '1',
                  '@media(max-width: 767px)': {
                    fontSize: '10px',
                  },
                },
              },
              '&.slick-active': {
                button: {
                  '::before': {
                    color: '#F4CC46',
                    opacity: '1',
                  },
                },
              },
            },
          },
          '.slick-slide': {
            padding: '0 8px',
            transform: 'scale(0.9)',
            transitionProperty: 'all',
            transitionDuration: '300ms',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            '@media(max-width: 767px)': {
              padding: '0px',
              transform: 'none',
            },
          },
          '.slick-slide.slick-current': {
            transform: ['scale(1)', 'scale(1.1)'],
            '@media(max-width: 767px)': {
              transform: 'none',
            },
          },
        }"
        v-bind="settings"
      >
        <c-box
          v-for="(item, index) in filteredBanners"
          :key="'banner-' + index"
          as="a"
          target="_blank"
          pos="relative"
          class="item"
          max-height="500px"
          mx="auto"
          :mt="['0px', '30px']"
          :mb="[isAuthenticated ? '-7px' : '0px', '50px']"
          @click="handleClick(item)"
        >
          <c-image
            :src="item.image"
            :srcset="item.image"
            alt="dietela"
            w="auto"
            :height="['auto', '360px']"
            :border-radius="['0px', '24px']"
            :box-shadow="['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']"
          />
        </c-box>
      </VueSlickCarousel>
      <c-box
        v-else
      >
        <SkeletonBanner />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapGetters, mapActions } from 'vuex'
import { CBox, CImage } from '@chakra-ui/vue'
import SkeletonBanner from '@/components/skeletons/skeleton-banner.vue'

export default {
  name: 'BannerComp',
  components: {
    VueSlickCarousel,
    CBox,
    CImage,
    SkeletonBanner,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      banners: 'clients/banners',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    settings() {
      return {
        arrows: true,
        dots: true,
        centerPadding: '30px',
        centerMode: true,
        slidesToShow: 2,
        infinite: true,
        draggable: false,
        autoplay: true,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              arrows: false,
              centerPadding: '0px',
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              variableWidth: false,
            },
          }, 
        ],
      }
    },
    filteredBanners: function() {
      if (this.banners.length == 0) { return [] }
      const data = this.banners.filter((i) => { return i?.isBanner })
      if (data?.length == 1) {
        return data.concat(data, data)
      } else if (data?.length == 2) {
        return data.concat(data)
      }
      return data.slice(0, 5)
    },
  },
  async created() {
    this.isLoading = true
    await this.loadBanners()
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      loadBanners: 'clients/getBanners',
    }),
    handleClick(item) {
      const isExternalLink = item.link && item.link.startsWith('http')
      if (isExternalLink && !item.buttonText) {
        window.open(item.link, '_blank')
        return
      }

      this.$router.push({
        name: 'client.promo',
        params: { id: item.id },
      })
    },
  },
}
</script>
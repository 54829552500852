<template>
  <c-box
    pos="relative"
    w="100%"
    max-width="1100px"
    d="flex"
    justify-content="center"
    flex-direction="column"
    align-items="center"
    :py="['16px', '8px']"
    :px="['16px', '8px']"
    mx="auto"
    mb="16px"
    background-color="#FFF"
    :border-radius="['0', '16px']"
  >
    <c-flex
      flex-direction="row"
      justify-content="center"
      align-items="flex-start"
      :mb="['16px', '32px']"
      :px="['0px', '0px']"
      :pb="['6px', '0px']"
      w="100%"
      :border-bottom="['1px solid #C4C4C4', 'none']"
    >
      <c-text
        :font-size="['16px', '28px']"
        font-weight="bold"
        color="#3A3A3A"
        font-family="Roboto"
        mr="6px"
      >
        Dietela, Solusi Untuk Masalah Dietmu
      </c-text>
      <c-image 
        :src="require('@/assets/ic-lightbulb.svg')"
        :height="['25px', '30px']"
        :width="['25px', '30px']"
      />
    </c-flex>
    <c-grid
      w="100%"
      :template-columns="['repeat(4, 1fr)', 'repeat(4, 1fr)']"
      :gap="['0', '56px']"
      mx="auto"
    >
      <c-flex
        v-for="(item, index) in advantages"
        :key="index"
        w="100%"
        :flex-direction="['row', 'column']"
        :align-items="['flex-start', 'center']"
      >
        <c-flex
          flex-direction="column"
          :justify-content="['space-between', 'center']"
          w="100%"
          h="100%"
          :border-right="[advantages.length == (index + 1) ? 'none' : '0px solid #C4C4C4', 'none']"
          flex-shrink="0"
        >
          <c-flex
            flex-direction="row"
            :justify-content="['flex-start', 'center']"
            :align-items="['flex-start', 'center']"
            w="100%"
          >
            <c-text
              :w="['100%', 'fit-content']"
              color="#000000"
              :font-size="['12px', '16px']"
              font-weight="500"
              font-family="Roboto"
              :mr="['0px', '8px']"
              text-align="center"
              :order="['2', '1']"
            >
              {{ item.name }}
            </c-text>
            <c-image
              :display="['none', 'block']"
              :src="require('@/assets/tabler_circle-check.svg')"
              width="20px"
              height="20px"
              flex-shrink="0"
              :order="['1', '2']"
              :mr="['8px', '0px']"
            />
          </c-flex>
          <c-flex
            :height="['90px', '150px']"
            justify-content="center"
            :align-items="['flex-end', 'center']"
          >
            <c-image
              :src="item.image"
              :width="[item.widthMd, item.width]"
              :height="[item.heightMd, 'auto']"
            />
          </c-flex>
        </c-flex>
        <!-- <c-box
          :flex-grow="['1', 'unset']"
        >
          <c-text
            :font-size="['12px', '16px']"
            font-weight="400"
            color="#555555"
            font-family="Roboto"
            :text-align="['left', 'center']"
            :pl="['12px', '0px']"
          >
            {{ item.description }}
          </c-text>
        </c-box> -->
      </c-flex>
    </c-grid>
  </c-box>
</template>

<script>
export default {
  name: 'AdvantagesComp',
  data() {
    return {
      advantages: [
        {
          name: 'Berat Badan',
          image: 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/solusi_beratbadan.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675384538513',
          width: '110px',
          widthMd: '75px',
          height: '130px',
          heightMd: '70px',
          description: 'Ingin menurunkan berat badan dengan target tertentu, atau keluar dari lingkaran diet yang menyiksa? Dietela punya solusi terbaik!',
        }, {
          name: 'Persiapan Kehamilan',
          image: 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/solusi_kehamilan.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675384539103',
          width: '140px',
          widthMd: '75px',
          height: '150px',
          heightMd: '80px',
          description: "Tahukah kamu, kesehatan janin di 'program' sejak saat kehamilan? Dietela hadir untuk bumil dan janin yang sehat.",
        }, {
          name: 'Diet Busui',
          image: 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/solusi_busui.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675384539073',
          width: '99px',
          widthMd: '56px',
          height: '101px',
          heightMd: '70px',
          description: 'Berat badan melonjak setelah melahirkan? Mau diet tapi takut ASI tidak lancar? Dietela punya solusinya.',
        }, {
          name: 'Penyakit',
          image: 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/solusi_penyakit.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675384539173',
          width: '100px',
          widthMd: '70px',
          height: '103px',
          heightMd: '80px',
          description: 'Punya keluhan seperti kolesterol, maag, diabetes, asam urat atau penyakit lainnya? Dietisien tersertifikasi di Dietela siap membantumu.',
        },
      ],
    }
  },
}
</script>

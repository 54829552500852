<template>
  <c-box
    :w="['100%', '80%']"
    max-width="1100px"
    mx="auto"
    justify="center"
    align="center"
    :pb="['12px', '1rem']"
  >
    <c-flex
      flex-direction="row"
      justify-content="space-between"
      :px="['16px', '0px']"
      :mb="['5px', '0px']"
      align-items="center"
    >
      <c-text
        font-family="Roboto"
        :font-size="['16px', '28px']"
        :font-weight="['500', '700']"
      >
        Panduan diet
      </c-text>
      <c-link
        v-if="false"
        color="#008C81"
        :font-size="['12px', '16px']"
        font-weight="400"
        font-family="Roboto"
        as="router-link"
        :to="{
          name: 'content-challenge.coaching-course' 
        }"
      >
        Lihat Semua
      </c-link>
    </c-flex>
    <c-box :px="['10px', '0px']">
      <VueSlickCarousel 
        v-if="coachingCourse.length > 0" 
        v-chakra="{
          '.slick-slide': {
            padding: '10px 10px',
            '@media(max-width: 767px)': {
              padding: '0 5px',
            }
          }
        }"
        v-bind="settings"
      >
        <c-box
          v-for="(item, index) in duplicateCoachingCourse"
          :key="index"
          bg-color="white"
          border-radius="12px"
          :h="['150px', '250px']"
          :box-shadow="['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)']"
          overflow="hidden"
          draggable="true"
          cursor="pointer"
          :pointer-events="disabledEvent ? 'none' : 'auto'"
          user-select="none"
          @dragstart.prevent="onDrag"
          @click.prevent="openCoachingCourse(item.id)"
        >
          <c-box
            :h="['80px', '150px']"
            :mb="['5px', '10px']"
          >
            <c-image
              :src="
                item?.coverImage
                  ? item?.coverImage
                  : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039'
              "
              alt="dietela"
              :max-h="['80px', '150px']"
              w="100%"
              object-fit="cover"
              user-select="none"
            />
          </c-box>
          <c-box
            :h="['70px', '80px']"
            overflow="hidden"
            px="10px"
          >
            <c-text
              :font-weight="['400', '500']"
              :font-size="['14px', '18px']"
              color="#111111"
              font-family="Roboto"
              text-align="left"
              mb="5px"
            >
              {{ item.title }}
            </c-text>
          </c-box>
        </c-box>
      </VueSlickCarousel>
    </c-box>
  </c-box>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'CoachingCourse',
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        draggable: true,
        swipeToSlide: true,
        touchMove: true,
        autoplay: true,
        autoplaySpeed: 10000,
        touchThreshold: 20,
        waitForAnimate: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              draggable: true,
              swipeToSlide: true,
              touchMove: true,
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
      disabledEvent: false,
    }
  },
  computed: {
    ...mapGetters({
      coachingCourse: 'general/coachingCourse',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    duplicateCoachingCourse() {
      if (this.coachingCourse.length < 4) {
        return this.coachingCourse.concat(this.coachingCourse)
      }
      return this.coachingCourse
    },
  },
  watch: {
    disabledEvent: {
      handler: _.debounce(function() {
        if (this.disabledEvent) {
          this.disabledEvent = false
        }
      }, 500),
    },
  },
  async mounted() {
    this.loadCoachingCourse()
  },
  methods: {
    ...mapActions({
      loadCoachingCourse: 'general/getCoachingCourse',
    }),
    onDrag() {
      this.disabledEvent = true
    },
    openCoachingCourse(id) {
      if (this.$isMobile()) {
        return this.$router.push({
          name: 'content-challenge.detail',
          params: {
            id: id,
          },
        })
      }
      setTimeout(() => {
        if (!this.disabledEvent) {
          this.$router.push({
            name: 'content-challenge.detail',
            params: {
              id: id,
            },
          })
        }
      }, 100)
    },
  },
}
</script>
